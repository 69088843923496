import { Step, StepLabel, Stepper, ThemeProvider } from '@mui/material';
import Button from '@mui/material/Button';
import { useState } from 'react';
import catalogTheme from '../../../styling/themes/catalogTheme';

interface PropertyUpdateStatusStepperProps {
	currentStep: number;
	maxSteps: number;
	onSave: () => void;
	onCancel: () => void;
	updateStep: (number) => void;
	blockNextStep: boolean;
}

const PropertyUpdateStatusStepperWithButtons = ({
	currentStep,
	maxSteps,
	onSave,
	onCancel,
	updateStep,
	blockNextStep,
}: PropertyUpdateStatusStepperProps) => {
	const [activeStep] = useState(currentStep);
	const propertyUpdateStatusDict = { 0: 'Properties', 1: 'Property Set', 2: 'Configuration' };

	const onNext = () => {
		if (currentStep === maxSteps) {
			onSave();
		} else {
			updateStep(currentStep + 1);
		}
	};

	const onBack = () => {
		if (currentStep === 0) {
			onCancel();
		} else {
			updateStep(currentStep - 1);
		}
	};

	return (
		<ThemeProvider theme={catalogTheme}>
			<Button onClick={onBack}>{currentStep === 0 ? 'Cancel' : 'Back'}</Button>
			<Stepper alternativeLabel activeStep={activeStep} sx={{ width: 800 }}>
				{Object.entries(propertyUpdateStatusDict).map(([key, value]) => (
					<Step key={key}>
						<StepLabel>{value}</StepLabel>
					</Step>
				))}
			</Stepper>
			<Button disabled={blockNextStep} onClick={onNext}>
				{currentStep === maxSteps ? 'Save' : 'Next'}
			</Button>
		</ThemeProvider>
	);
};
export default PropertyUpdateStatusStepperWithButtons;
