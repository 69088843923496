// Styling
import '../styling/app.css';

import { ToastContainer } from '@lbc-toolkit/toast';
import { ToolkitProvider } from '@lbc-toolkit/toolkit-provider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FormatIcu } from '@tolgee/format-icu';
import { DevTools, Tolgee, TolgeeProvider } from '@tolgee/react';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import Config from '../configuration/config';
import { store } from '../context/store';
import UnauthorizedPage from '../pages/unauthorized/components/Unauthorized';
import { PrivateRoute } from '../routes/PrivateRoute';
import { PublicRoute } from '../routes/PublicRoute';
import { OpenAPI } from './../services/catalog';
import Dashboard from './container/Dashboard';

export const queryClient = new QueryClient();

function App() {
	OpenAPI.BASE = Config.CatalogServerUrl ?? '';

	const tolgee = Tolgee().use(DevTools()).use(FormatIcu()).init({
		apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
		apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
		defaultLanguage: 'de',
	});

	return (
		<Provider store={store}>
			<QueryClientProvider client={queryClient}>
				<TolgeeProvider tolgee={tolgee}>
					<div className='App'>
						<ToastContainer position='top-right' width='408px' />
						<ToolkitProvider>
							<Routes>
								<Route
									path='/'
									element={
										<PrivateRoute>
											<Dashboard />
										</PrivateRoute>
									}
								/>
								<Route
									path='/unauthorized'
									element={
										<PublicRoute>
											<UnauthorizedPage />
										</PublicRoute>
									}
								/>
							</Routes>
						</ToolkitProvider>
					</div>
				</TolgeeProvider>
			</QueryClientProvider>
		</Provider>
	);
}

export default App;
