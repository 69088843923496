// Styles
import useKeycloak from '../hooks/useKeycloak';
import { Spinner } from '@lbc-toolkit/spinner';
import './Routes.scss';
import { Navigate } from 'react-router-dom';

export const PrivateRoute = ({ children }) => {
	const { isInitialized, tokenInitialized } = useKeycloak();

	if (!isInitialized || !tokenInitialized) {
		return (
			<Spinner
				className="spinner-route-loading"
				spin={true}
			/>
		);
	}

	if (false) {
		return <Navigate to='/unauthorized' />;
	}

	return children;
};
