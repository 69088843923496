import { useEffect, useRef, useState } from "react";
import { Stack } from "@mui/material";
import useFormulaCalculator from './hooks/useFormulaCalculator';
import FormulaTextField from './components/FormulaTextField';
import CalculatorExtension from './sub-features/CalculatorExtension/CalculatorExtension';

type FormulaCalculatorProps = {
    initialFormula: string;
    onFormulaChange?: (newFormula: string) => void;
    selectedUnitType?: string;
};

const FormulaCalculator = ({ initialFormula, onFormulaChange = () => {}, selectedUnitType }: FormulaCalculatorProps) => {
    const {
        formula,
        setFormula,
        isValid,
        fetchValidateFormula,
        isValidatingPending,
        addTerm,
    } = useFormulaCalculator(initialFormula);
    const [formulaSelectType, setFormulaSelectType] = useState<string>('Prop');

    const prevFormulaRef = useRef<string>(formula);

    useEffect(() => {
        if (prevFormulaRef.current === formula) {
            return;
        }
        onFormulaChange(formula);
        prevFormulaRef.current = formula;
    }, [isValid, formula, onFormulaChange]);

    return (
        <Stack direction='column'>
            <FormulaTextField
                formula={formula}
                setFormula={setFormula}
                fetchValidateFormula={fetchValidateFormula}
                isValid={isValid}
                setIsValid={() => {}}
                isValidatingPending={isValidatingPending}
            />
            <Stack direction='row' gap={20} justifyContent='space-between'>
                <CalculatorExtension
                    formulaSelectType={formulaSelectType}
                    setFormulaSelectType={setFormulaSelectType}
                    selectedUnitType={selectedUnitType}
                    addTerm={addTerm}
                />
            </Stack>
        </Stack>
    );
};

export default FormulaCalculator;
