import React, { useEffect, useState } from 'react';
import '../../../../../styling/Details.scss';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Text } from '@lbc-toolkit/text';
import { TextInput } from '@lbc-toolkit/textinput';
import { useTranslate } from '@tolgee/react';
import { useAppDispatch, useAppSelector } from '../../../../../context/hooks';
import { PropertyDto } from '../../../../../services/catalog';
import { RootState } from '../../../../../context/store';
import { setSelectedProperty } from '../../../../../context/mappingsReducer';
import PropertyIcon from '../../../../../components/icons/PropertyIcon';

interface Props {
	setBlockNextStep: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectableProperties = ({ setBlockNextStep }: Props) => {
	const { t } = useTranslate();
	const dispatch = useAppDispatch();
	const selectableProperties: PropertyDto[] = useAppSelector((state: RootState) => state.mappings.selectables);
	const selectedProperty: PropertyDto | null = useAppSelector((state: RootState) => state.mappings.selectedProperty);
	const [searchInput, setSearchInput] = useState<string>('');

	/**
	 * Decides if a property can be shown or not, depending the input of the searchbard
	 * @param component
	 * @returns
	 */
	const displayProperty = (property: PropertyDto) => {
		const lowerSearchInput: string = searchInput.toLocaleLowerCase();
		return property.name?.toLowerCase().includes(lowerSearchInput);
	};

	useEffect(() => {
		if (selectedProperty) {
			setBlockNextStep(false);
		} else {
			setBlockNextStep(true);
		}
	}, [selectedProperty]);

	return (
		<div className='selectable-content fullheight'>
			<Text type='h6' className='mb-2'>
				{t('selectProperty')}
			</Text>
			<div className='justify-content-space-between-horizontal'>
				<TextInput
					placeholder={t('search')}
					onChange={(value: string) => {
						setSearchInput(value);
					}}
				/>
			</div>

			<div style={{ maxHeight: '300px', overflowY: 'auto' }}>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>{t('name')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{selectableProperties
							.filter((property) => displayProperty(property))
							.map((property: PropertyDto) => {
								return (
									<TableRow className='selectable-row' key={property.id}>
										<TableCell
											align='left'
											className={
												property.id === selectedProperty?.id
													? 'selectable-item-active'
													: 'selectable-item'
											}
											onClick={() => dispatch(setSelectedProperty(property))}
										>
											<PropertyIcon property={property} showPropertyName={true} />
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
			</div>
		</div>
	);
};

export default SelectableProperties;
