// STYLING
import './Footer.scss';

import { useTranslate } from '@tolgee/react';
import liebherrLogoWhite from '../../../assets/images/Liebherr-Logo-white.png';

const Footer = () => {
	const { t } = useTranslate();

	return (
		<div className='footer'>
			<img src={liebherrLogoWhite} height='25vh' className='navbar-logo' />
			<div className='footer-links'>
				<div
					onClick={() => {
						/*LINK TO IMPRESSUM*/
					}}
				>
					{t('impressum')}
				</div>
				<div
					onClick={() => {
						/*LINK TO PRIVACY*/
					}}
				>
					{t('privacy')}
				</div>
				<div
					onClick={() => {
						/*LINK TO TERMSOFUSE*/
					}}
				>
					{t('termsOfUse')}
				</div>
			</div>
			<div>Copyright © Liebherr 2023</div>
		</div>
	);
};
export default Footer;
