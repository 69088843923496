// STYLING
import '../Form.scss';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faRectangleList } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button as LiebherrBtn } from '@lbc-toolkit/button';
import { Text } from '@lbc-toolkit/text';
import { useTranslate } from '@tolgee/react';
import { setCreateMode } from '../../../context/appReducer';
import { useAppDispatch } from '../../../context/hooks';

const PropertyHeader = () => {
	const { t } = useTranslate();
	const dispatch = useAppDispatch();

	/**
	 * Opens a modal to create a property
	 */
	const OnCreateNew = () => {
		dispatch(setCreateMode(true));
	};

	return (
		<div className='dashboard-content-header'>
			<div className='header-controls'>
				<Text className='header-text' type='h2'>
					{t('properties')}
				</Text>
				<FontAwesomeIcon className='header-icon' size='xs' icon={faRectangleList as IconProp} />
				<div className='vr' />
				<LiebherrBtn icon={faPlus} label={t('createNew')} onClick={OnCreateNew} />
			</div>
		</div>
	);
};

export default PropertyHeader;
