// REACT
import { useEffect, useState } from 'react';
// STYLING
import '../../../../styling/Details.scss';
// COMPONENTS
import { Button } from '@lbc-toolkit/button';
import { Text } from '@lbc-toolkit/text';
import { Paper } from '@mui/material';
import MappingTablePropertySet from './MappingTablePropertySet';
// API
// TRANSLATION
import { useTranslate } from '@tolgee/react';
import { setToast } from '../../../../context/appReducer';
import { useAppDispatch, useAppSelector } from '../../../../context/hooks';
import { setMappingsListNeedsReload, setSelectables, setAddMappingMode } from '../../../../context/mappingsReducer';
import { setProperties } from '../../../../context/propertiesReducer';
import { RootState } from '../../../../context/store';
import { Severity } from '../../../../models/models';
import { PropertySetDto, MappingDto, PropertiesService, PropertyDto, MappingsService, Status } from '../../../../services/catalog';

const MappingsList = () => {
	const { t } = useTranslate();
	const dispatch = useAppDispatch();
	const currentClass = useAppSelector((state: RootState) => state.classes.currentClass);
	const addMappingMode = useAppSelector((state: RootState) => state.mappings.addMappingMode);
	const mappingsListNeedsReload = useAppSelector((state: RootState) => state.mappings.mappingsListNeedsReload);
	const properties = useAppSelector((state: RootState) => state.properties.properties);
	const [inheritedPropertySets, setInheritedPropertySets] = useState<PropertySetDto[]>([]);
	const [relatedPropertySets, setRelatedPropertySets] = useState<PropertySetDto[]>([]);
	const [inheritedMappings, setInheritedMappings] = useState<MappingDto[]>([]);
	const [relatedMappings, setRelatedMappings] = useState<MappingDto[]>([]);

	/**
	 * Retrieves mapping after initial render
	 */
	useEffect(() => {
		PropertiesService.getProperties().then((retrievedProperties: PropertyDto[]) => {
			dispatch(setProperties(retrievedProperties));
			dispatch(setMappingsListNeedsReload(true));
		});
	}, []);

	/**
	 * Called everytime the mappingsList needs a reload.
	 * Reloads all information about related and inherited mappings,
	 * groups all properties by theire propertysets and saves them in a state.
	 */
	useEffect(() => {
		if (mappingsListNeedsReload === false) return;
		ReloadMappingsList();
		dispatch(setMappingsListNeedsReload(false));
	}, [mappingsListNeedsReload]);

	/**
	 * Everytime the propertysets for related and inherited mappings gets changed,
	 * this useEffect determines which of the properties are not assigned and can be
	 * selected in the add part.
	 */
	useEffect(() => {
		let distinctedProperties: PropertyDto[] = properties.filter((property: PropertyDto) => {
			return (
				relatedMappings.findIndex((m) => m.property?.id === property.id) === -1 &&
				inheritedMappings.findIndex((m) => m.property?.id === property.id) === -1
			);
		});
		dispatch(setSelectables(distinctedProperties));
	}, [relatedMappings, inheritedMappings]);

	const ReloadMappingsList = () => {
		MappingsService.getRelatedMappings(currentClass?.id!)
			.then((retrievedMappings: MappingDto[]) => {
				setRelatedMappings(retrievedMappings);
				const newRelatedPropertySets: PropertySetDto[] = [];
				retrievedMappings.forEach((relatedMapping: MappingDto) => {
					const currentProperty: PropertyDto | undefined = properties.find(
						(p) => p.id === relatedMapping.property?.id,
					);
					if (currentProperty === undefined) return;

					if (currentProperty.propertySetId === null) return;

					if (newRelatedPropertySets.find((p) => p.id === currentProperty.propertySetId)) {
						newRelatedPropertySets
							.find((pset) => pset.id === currentProperty.propertySetId)
							?.properties?.push(relatedMapping.property!);
					} else {
						const toAddPropertySet: PropertySetDto = {
							id: currentProperty.propertySetId,
							name: currentProperty.propertySetName,
							properties: [],
						};
						toAddPropertySet.properties!.push(relatedMapping.property!);
						newRelatedPropertySets.push(toAddPropertySet);
					}
				});
				setRelatedPropertySets(newRelatedPropertySets);
			})
			.catch((error: any) => {
				console.log(error.message);
				dispatch(
					setToast({
						show: true,
						severity: Severity.alert,
						message: t('errorFetchingData'),
					}),
				);
			});

		MappingsService.getInheritedMapping(currentClass?.id!)
			.then((retrievedMappings: MappingDto[]) => {
				setInheritedMappings(retrievedMappings);
				const newInheritedPropertySets: PropertySetDto[] = [];
				retrievedMappings.forEach((inheritedMapping: MappingDto) => {
					const currentProperty: PropertyDto | undefined = properties.find(
						(p) => p.id === inheritedMapping.property?.id,
					);
					if (currentProperty?.propertySetId === null) return;

					if (newInheritedPropertySets.find((p) => p.id === currentProperty?.propertySetId)) {
						newInheritedPropertySets
							.find((pset) => pset.id === currentProperty?.propertySetId)
							?.properties?.push(inheritedMapping.property!);
					} else {
						const toAddPropertySet: PropertySetDto = {
							id: currentProperty?.propertySetId,
							name: currentProperty?.propertySetName,
							properties: [],
						};
						toAddPropertySet.properties!.push(inheritedMapping.property!);
						newInheritedPropertySets.push(toAddPropertySet);
					}
				});
				setInheritedPropertySets(newInheritedPropertySets);
			})
			.catch((error: any) => {
				console.log(error.message);
				dispatch(
					setToast({
						show: true,
						severity: Severity.alert,
						message: t('errorFetchingData'),
					}),
				);
			});
	};

	const OnAddNewRelatedProperty = () => {
		dispatch(setAddMappingMode(true));
	};

	return (
		<>
			<Paper className='paper-layout justify-content-space-between-vertical'>
				<div>
					<Text type='h6' className='mb-2'>
						{t('own')}
					</Text>
					<table className='mapping-table'>
						{relatedPropertySets.map((propertySet: PropertySetDto) => {
							return (
								<MappingTablePropertySet
									key={propertySet.id}
									pset={propertySet}
									mset={relatedMappings}
									deletable={currentClass?.status === Status.Draft}
								/>
							);
						})}
					</table>
				</div>
				<div className='justify-content-right mb-2 mt-4'>
					<Button label={t('add')} onClick={OnAddNewRelatedProperty} />
				</div>
			</Paper>
			{addMappingMode ? (
				<></>
			) : (
				<Paper className='paper-layout'>
					<Text type='h6'>{t('inherited')}</Text>
					<table className='mapping-table'>
						{inheritedPropertySets.map((propertySet: PropertySetDto) => {
							return (
								<MappingTablePropertySet
									pset={propertySet}
									mset={inheritedMappings}
									deletable={false}
								/>
							);
						})}
					</table>
				</Paper>
			)}
		</>
	);
};

export default MappingsList;
