// REACT
import { useEffect, useState } from 'react';
// STYLING
import './Treeview.scss';
// COMPONENTS
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import {
	faCircleNodes,
	faFolder,
	faGear,
	faGears,
	faPlug,
	faPlus,
	faSquareChevronDown,
	faSquareChevronRight,
	faTrashCanXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from '@lbc-toolkit/spinner';
import { IconButton, Tooltip } from '@mui/material';
// REDUX
import { setCreateMode, setDeleteMode, setDetailsMode } from '../../../context/appReducer';
import { setCurrentClass, setSelectedParentClass } from '../../../context/classesReducer';
import { useAppDispatch, useAppSelector } from '../../../context/hooks';
import { RootState } from '../../../context/store';
// API
import { ClassificationDto, ClassificationsService, Status, UsageType } from '../../../services/catalog';
// TRANSLATION
import ClassificationTreeItems from './ClassificationTreeItems';
import { useTranslate } from '@tolgee/react';

type ClassificationTreeNodeProps = {
	classification: ClassificationDto;
	isOverview: boolean;
};
const ClassificationTreeNode = ({ classification, isOverview }: ClassificationTreeNodeProps) => {
	const { t } = useTranslate();
	const dispatch = useAppDispatch();
	const currentClass = useAppSelector((state: RootState) => state.classes.currentClass);
	const selectedParentClass = useAppSelector((state: RootState) => state.classes.selectedParentClass);
	const [expanded, setExpanded] = useState<boolean>(false);
	const [children, setChildren] = useState<ClassificationDto[]>([]);
	const [childrenLoading, setChildrenLoading] = useState<boolean>(false);
	const [disabled, _setDisabled] = useState<boolean>(false);
	const [canDelete, setCanDelete] = useState<boolean>(false);
	const [hasChildren, setHasChildren] = useState<boolean>(false);

	useEffect(() => {
		var isDraftStatus = classification.status === Status.Draft;
		setCanDelete(isDraftStatus);
	}, [classification]);

	useEffect(() => {
		setChildren(classification.children ?? []);
	}, [classification.children]);

	useEffect(() => {
		if (classification.id) {
			ClassificationsService.getChildren(classification.id)
				.then((children: ClassificationDto[]) => {
					setChildren(children);
					setHasChildren(children.length > 0);
				})
				.catch(() => {
					setHasChildren(false);
				});
		}
	}, [classification.id]);

	useEffect(() => {
		if (expanded && children.length === 0) {
			setChildrenLoading(true);
			if (isOverview) {
				ClassificationsService.getChildren(classification.id!)
					.then((children: ClassificationDto[]) => {
						setChildren(children);
					})
					.finally(() => {
						setChildrenLoading(false);
					});
			} else {
				ClassificationsService.getChildren(classification.id!)
					.then((children: ClassificationDto[]) => {
						setChildren(
							children.filter(
								(child) => child.id == currentClass?.id || child.usageType == currentClass?.usageType,
							),
						);
					})
					.finally(() => {
						setChildrenLoading(false);
					});
			}
		}
	}, [expanded]);

	const renderIndicatorIcon = () => {
		const iconMapping = {
			[UsageType.Collector]: faFolder,
			[UsageType.Connector]: faPlug,
			[UsageType.Composition]: faGears,
			[UsageType.CompositionVariant]: faCircleNodes,
			default: faGear,
		};
		const icon = iconMapping[classification.usageType!] || iconMapping.default;
		const titleMapping = {
			[UsageType.Collector]: t('collectorClass'),
			[UsageType.Connector]: t('connector'),
			[UsageType.Composition]: t('composition'),
			[UsageType.CompositionVariant]: t('variant'),
			default: t('component'),
		};
		const title = titleMapping[classification.usageType!] || titleMapping.default;

		return (
			<Tooltip title={title} arrow>
				<div>
					<FontAwesomeIcon className='treeview-listitem-indicator' color='black' icon={icon as IconProp} />
				</div>
			</Tooltip>
		);
	};

	const OnClickTreeItem = () => {
		if (isOverview) {
			dispatch(setCurrentClass(classification === currentClass ? null : classification));
		} else if (!disabled) {
			dispatch(setSelectedParentClass(classification === selectedParentClass ? null : classification));
		}
	};

	const OnDetails = () => {
		dispatch(setDetailsMode(true));
	};

	const OnDelete = () => {
		dispatch(setDeleteMode(true));
	};

	const OnAddChild = () => {
		dispatch(setSelectedParentClass(classification));
		dispatch(setCreateMode(true));
	};

	return (
		<li className='treeview-listitem'>
			<div
				className={
					(isOverview && currentClass?.id === classification.id) ||
					(!isOverview && selectedParentClass?.id === classification.id)
						? 'treeview-item-active'
						: 'treeview-item'
				}
				onClick={OnClickTreeItem}
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
					}}
				>
					{hasChildren && (
						<div
							onClick={() => {
								if (!disabled) {
									setExpanded(!expanded);
								}
							}}
						>
							<IconButton>
								<FontAwesomeIcon
									className={disabled ? 'treeview-item-disabled' : 'fa-xs'}
									color={expanded ? '#ffd000' : '#000000'}
									icon={
										expanded
											? (faSquareChevronDown as IconProp)
											: (faSquareChevronRight as IconProp)
									}
								/>
							</IconButton>
						</div>
					)}
					<div className='treeview-listitem-indicator-container'>{renderIndicatorIcon()}</div>
					<div className={disabled ? 'treeview-item-disabled' : 'fa-xs'}>{classification.name}</div>
					{isOverview && (
						<div onClick={OnAddChild}>
							<Tooltip title={t('createNewAsChildInfo')} arrow>
								<IconButton>
									<FontAwesomeIcon className='fa-xs' color='black' icon={faPlus as IconProp} />
								</IconButton>
							</Tooltip>
						</div>
					)}
				</div>
				{isOverview && (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							paddingRight: '2vw',
						}}
					>
						<div>
							<IconButton onClick={OnDetails}>
								<FontAwesomeIcon className='fa-2xs' color='#3e86d6' icon={faPencil as IconProp} />
							</IconButton>
						</div>
						{canDelete && (
							<div>
								<IconButton onClick={OnDelete}>
									<FontAwesomeIcon
										className='fa-2xs'
										color='#d64640'
										icon={faTrashCanXmark as IconProp}
									/>
								</IconButton>
							</div>
						)}
					</div>
				)}
			</div>
			{childrenLoading && (
				<div className='table-spinner pt-0'>
					<Spinner spin={true} />
				</div>
			)}
			{expanded && <ClassificationTreeItems classifications={children} isOverview={isOverview} />}
		</li>
	);
};

export default ClassificationTreeNode;
