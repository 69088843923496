// STYLING
import '../../styling/Modal.scss';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faQuestionCircle, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button as LbcButton } from '@lbc-toolkit/button';
import { Spinner } from '@lbc-toolkit/spinner';
import { TextArea } from '@lbc-toolkit/textarea';
import { TextInput } from '@lbc-toolkit/textinput';
import { Checkbox, FormControl, MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import ClassificationTree from '../../components/navigation/treeviews/ClassificationTree';
import { setCreateMode, setToast } from '../../context/appReducer';
import { setClassTreeNeedsReload, setCurrentClass, setSelectedParentClass, setTreeviewSearch } from '../../context/classesReducer';
import { useAppDispatch, useAppSelector } from '../../context/hooks';
import { RootState } from '../../context/store';
import useSearchedClassifications from '../../hooks/UseSearchedClassifications';
import { Severity } from '../../models/models';
import { ClassificationDto, ClassificationsService, Status, UsageType } from '../../services/catalog';

const CreateClassContent = () => {
	const { t } = useTranslate();
	const dispatch = useAppDispatch();
	const [wantsToClose, setWantsToClose] = useState<boolean>(false);
	const [classTreeSearchText, setClassTreeSearchText] = useState<string>('');
	const { classifications } = useSearchedClassifications(classTreeSearchText);
	const classTreeNeedsReload = useAppSelector((state: RootState) => state.classes.classTreeNeedsReload);

	// Input states
	const [name, setName] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const [usageType, setUsageType] = useState<UsageType | null>(null);
	const [isCompositionVariant, setIsCompositionVariant] = useState<boolean>(
		usageType === UsageType.CompositionVariant,
	);

	const selectedParentClass = useAppSelector((state: RootState) => state.classes.selectedParentClass);

	/**
	 * Should the parent change, the usageType has to change as well eventually.
	 */
	useEffect(() => {
		if (!selectedParentClass || !selectedParentClass.usageType) {
			return;
		}
		if (selectedParentClass.usageType !== UsageType.Collector) {
			setUsageType(selectedParentClass.usageType);
		}
	}, [selectedParentClass]);
	/**
	 * Required logic for loading the classification
	 */
	useEffect(() => {
		setCurrentClass(null);
		dispatch(setClassTreeNeedsReload(true));
		if (selectedParentClass?.usageType) {
			setUsageType(selectedParentClass.usageType);
		}
	}, []);

	useEffect(() => {
		if (usageType === UsageType.Composition && isCompositionVariant) {
			setUsageType(UsageType.CompositionVariant);
			setIsCompositionVariant(true);
		} else if (usageType !== UsageType.CompositionVariant) {
			setIsCompositionVariant(false);
		}
	}, [usageType]);

	/**
	 * Renders the composition variant checkbox depending on the usageType and editMode + draft status
	 * @returns
	 */
	const renderCompositionVariantCheckBox = () => {
		if (usageType === UsageType.Composition || usageType === UsageType.CompositionVariant) {
			const isChecked =
				selectedParentClass?.usageType === UsageType.CompositionVariant ? true : isCompositionVariant;
			if (selectedParentClass?.usageType !== UsageType.Composition) {
				return (
					<>
						<Row md={8} className='align-items-center ml-1'>
							<Col className='pr-0'>
								<Checkbox
									checked={isChecked || usageType === UsageType.CompositionVariant}
									onChange={(value) => onChangeCompositionVariantSelection(value.target.checked)}
								/>
							</Col>
							<Col className='pl-0'>{t('variant')}</Col>
						</Row>
						<hr />
					</>
				);
			}
		}
	};

	/**
	 * Handles the change of the composition variant checkbox
	 * @param value
	 */
	const onChangeCompositionVariantSelection = (value: boolean) => {
		if (value) {
			setUsageType(UsageType.CompositionVariant);
			setIsCompositionVariant(true);
		} else {
			setUsageType(UsageType.Composition);
			setIsCompositionVariant(false);
		}
	};

	/**
	 * Handles closing of the create modal
	 */
	const OnCancel = () => {
		if (name !== '' || description !== '' || usageType !== null || selectedParentClass !== null) {
			setWantsToClose(true);
		} else dispatch(setCreateMode(false));
	};

	/**
	 * Resets all input fields to their original state
	 */
	const ResetInputs = () => {
		setName('');
		setDescription('');
		setUsageType(null);
		dispatch(setSelectedParentClass(null));
	};

	/**
	 * Checks if all input fields are filled
	 */
	function CheckInputs(): boolean {
		if (name == '' || description == '' || usageType == null) {
			dispatch(
				setToast({
					show: true,
					severity: Severity.warning,
					message: t('info_inputfieldsAreEmpty'),
				}),
			);
			return true;
		}
		return false;
	}

	/**
	 * Handles the creation of the class
	 */
	const OnCreate = () => {
		if (CheckInputs()) return;

		const classification: ClassificationDto = {
			id: '',
			name: name,
			description: description,
			usageType: usageType!,
			parentId: selectedParentClass?.id,
			children: [],
			status: Status.Draft,
		};

		ClassificationsService.createClassification(classification)
			.then(() => {
				dispatch(
					setToast({
						show: true,
						severity: Severity.success,
						message: t('createClassSuccess'),
					}),
				);
				dispatch(setCreateMode(false));
				dispatch(setClassTreeNeedsReload(true));
				ResetInputs();
			})
			.catch((_error: any) => {
				dispatch(
					setToast({
						show: true,
						severity: Severity.alert,
						message: t('createFailed'),
					}),
				);
			});
	};

	/**
	 * Handles searching in classification tree by query
	 */
	const OnSearchInClassificationTree = () => {
		dispatch(setTreeviewSearch(classTreeSearchText));
	};

	/**
	 * Handles abort of closing
	 */
	const OnAbortClose = () => {
		setWantsToClose(false);
	};

	/**
	 * Handles confirmation of closing
	 */
	const OnConfirmClose = () => {
		dispatch(setCreateMode(false));
		setWantsToClose(false);
		ResetInputs();
	};
	/**
	 * Sets usage type
	 */
	const handleUsageTypeChange = (event: SelectChangeEvent) => {
		if (usageType === UsageType.CompositionVariant) {
			setIsCompositionVariant(true);
		}
		setUsageType(event.target.value as UsageType);
	};
	/**
	 * Returns the icon for a dropdown list
	 */
	const newIcon = (props) => {
		return <FontAwesomeIcon {...props} icon={faChevronDown} style={{ transform: 'rotate(0deg)' }} />;
	};
	/**
	 * Function which greys out a usage type option based on condition
	 */
	function isUsageTypeDisabled(value: UsageType): boolean {
		if (selectedParentClass?.usageType === UsageType.CompositionVariant && value == UsageType.Composition) {
			return false;
		}
		if (selectedParentClass?.usageType == UsageType.Collector) {
			return false;
		}
		if (usageType != UsageType.Collector && selectedParentClass?.usageType != null) {
			return selectedParentClass.usageType != value;
		}
		return false;
	}
	/**
	 * Dropdown list for usage types with the logic allowing
	 * restriction of a usage type option
	 */
	const usageTypeDropdown = () => {
		return (
			<Select
				IconComponent={newIcon}
				value={usageType === 'CompositionVariant' ? 'Composition' : usageType || ''}
				onChange={handleUsageTypeChange}
				sx={{ height: 40, width: '275px' }}
			>
				{Object.keys(UsageType).map((key) => {
					const value = UsageType[key];
					if (value !== UsageType.CompositionVariant) {
						return (
							<MenuItem key={key} value={value} disabled={isUsageTypeDisabled(value)}>
								{value === 'CompositionVariant' ? 'Composition' : value}
							</MenuItem>
						);
					}
					return null;
				})}
			</Select>
		);
	};

	return (
		<>
			<Modal.Body className='modal-content-layout'>
				<Row className='pb-4'>
					<Col md={2}>{t('name')}</Col>
					<Col md={10}>
						<TextInput
							width='100%'
							label={t('required')}
							placeholder={t('placeholderInputName')}
							onChange={(value) => setName(value)}
						/>
					</Col>
				</Row>
				<hr />
				<Row className='pb-4 pt-4'>
					<Col md={2}>{t('description')}</Col>
					<Col md={10}>
						<TextArea
							width='100%'
							label={t('required')}
							placeholder={t('enterDescription')}
							onChange={(value: string) => {
								setDescription(value);
							}}
						/>
						<div
							className={
								description.length > 255
									? 'modal-content-description-length-tooLong'
									: 'modal-content-description-length'
							}
						>
							{description.length > 255
								? t('tooManyCharacters') + description.length + '/255'
								: description.length + '/255'}
						</div>
					</Col>
				</Row>
				<hr />
				<Row className='pb-4 pt-4'>
					<Col md={2}>{t('usage')}</Col>
					<Col md={10} className='modal-content-tooltip-row'>
						{usageTypeDropdown()}
						<Tooltip className='modal-content-tooltip' placement='right' title={t('info_usageType')} arrow>
							<div>
								<FontAwesomeIcon size='sm' color='gray' icon={faQuestionCircle as IconProp} />
							</div>
						</Tooltip>
						{renderCompositionVariantCheckBox()}
					</Col>
				</Row>
				<hr />
				<Row className='pb-4 pt-4'>
					<Col md={2}>{t('parentClass')}</Col>
					<Col md={10}>
						<div className='modal-content-selectedParentClass'>
							{selectedParentClass?.name ?? t('placeholderSelectFromTree')}
						</div>
						<div className='modal-content-treeview'>
							<div className='modal-content-treeview-header'>
								<h6 className='modal-content-treeview-header-title'>{t('classifications')}</h6>
								<InputGroup className='modal-content-treeview-header-inputgroup'>
									<Tooltip
										title={classTreeSearchText.trim().length < 3 ? t('atLeast3Characters') : ''}
										placement='bottom-start'
									>
										<Form.Control
											className='modal-content-treeview-header-searchBar'
											placeholder={t('search')}
											onChange={(e) => setClassTreeSearchText(e.target.value)}
										/>
									</Tooltip>
									<Button className='modal-searchbar-button' onClick={OnSearchInClassificationTree}>
										{classTreeNeedsReload ? (
											<Spinner style={{ color: 'black' }} spin={true} />
										) : (
											<FontAwesomeIcon
												className='fa-xs'
												id='modalSearchIconBtn'
												color='black'
												icon={faSearch as IconProp}
											/>
										)}
									</Button>
								</InputGroup>
							</div>
							<div className='modal-content-treeview-container'>
								<ClassificationTree classifications={classifications} />
							</div>
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				{wantsToClose ? (
					<>
						<div>{t('wantToClose')}</div>
						<LbcButton type='secondary' onClick={OnAbortClose} label={t('no')} />
						<LbcButton type='primary' onClick={OnConfirmClose} label={t('yes')} />
					</>
				) : (
					<>
						<LbcButton type='secondary' onClick={OnCancel} label={t('cancel')} />
						<LbcButton type='primary' onClick={OnCreate} label={t('save')} />
					</>
				)}
			</Modal.Footer>
		</>
	);
};

export default CreateClassContent;
