import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@lbc-toolkit/button';
import { TextInput } from '@lbc-toolkit/textinput';
import { IconButton, Paper } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import '../../styles/PropertyModal.scss';
import { useTranslate } from '@tolgee/react';
import StatusStepper from '../../../../components/navigation/stepper/StatusStepper';
import { useAppSelector, useAppDispatch } from '../../../../context/hooks';
import { setCurrentProperty, setEditMode, setPropertiesNeedReload } from '../../../../context/propertiesReducer';
import { RootState } from '../../../../context/store';
import { EventType, Severity } from '../../../../models/models';
import { PropertyDto, Status, PropertiesService, PropertyDiscriminator, SimplePropertyDto, TablePropertyDto, ObjectPropertyDto, CalculatedPropertyDto } from '../../../../services/catalog';
import TableDetails from './TableDetails';
import ObjectDetails from './ObjectDetails';
import SimpleDetails from './SimpleDetails';
import CalculatedDetails from './CalculatedDetails';
import { setToast } from '../../../../context/appReducer';

interface Props {
	property: PropertyDto;
}

const General = ({ property }: Props) => {
	const { t } = useTranslate();
	const editMode = useAppSelector((state: RootState) => state.properties.editMode);
	const dispatch = useAppDispatch();
	const [event, setEvent] = useState<EventType | null>(null);
	const [inputsFilled, setInputsFilled] = useState<boolean>(false);
	const [childIsValid, setChildIsValid] = useState(true);
	const [name, setName] = useState<string>(property.name ? property.name : '');
	const [status, setStatus] = useState<Status>(property.status ? property.status : Status.Draft);
	const [tempStatus, setTempStatus] = useState<Status>(property.status ? property.status : Status.Draft);
	const [description, setDescription] = useState<string>(property.description ? property.description : '');

	const onEdit = () => {
		setEvent(EventType.UPDATE_PROPERTY_EVENT);
		dispatch(setEditMode(true));
	};

	const onCancelEdit = () => {
		setEvent(EventType.RESET_PROPERTY_EVENT);
		dispatch(setEditMode(false));
		setName(property.name ? property.name : '');
		setDescription(property.description ? property.description : '');
		setStatus(property.status ? property.status : Status.Draft);
	};

	const onSaveEdit = () => {
		setEvent(EventType.SAVE_PROPERTY_EVENT);
	};

	/**
	 * Updates the control state if every input is filled
	 * Rollbacks if the conditions are not met anymore
	 */
	useEffect(() => {
		setInputsFilled(Boolean(name) && Boolean(description) && childIsValid);
	}, [name, description, setInputsFilled, childIsValid]);

	/**
	 * TODO: keine Ahnung warum, aber das wird bis zu drei mal aufgerufen.
	 * Das führt zu Duplikaten in der PropertyAssignment Tabelle in der DB.
	 */
	const updateProperty = useCallback(
		(property: PropertyDto) => {
			dispatch(setEditMode(false));
			property.name = name;
			property.description = description;
			property.status = status;
			PropertiesService.updateProperty(property)
				.then((response: any) => {
					dispatch(setCurrentProperty(response));
					dispatch(setPropertiesNeedReload(true));
					dispatch(setCurrentProperty(property));

					dispatch(
						setToast({
							show: true,
							severity: Severity.success,
							message: t('updatePropertySuccess'),
						}),
					);
				})
				.catch((error: any) => {
					console.log(error.message);
					dispatch(
						setToast({
							show: true,
							severity: Severity.warning,
							message: t('updateFailed'),
						}),
					);
				})
				.finally(() => {
					setEvent(null);
				});
			setTempStatus(status);
		},
		[description, name, dispatch, status],
	);

	const getExtendenFormByPropertyType = () => {
		switch (property.propertyDiscriminator) {
			case PropertyDiscriminator.Simple: {
				return (
					<SimpleDetails
						property={property as SimplePropertyDto}
						event={event}
						onUpdateProperty={updateProperty}
						setEvent={setEvent}
						onValidationChange={setChildIsValid}
					/>
				);
			}
			case PropertyDiscriminator.Table: {
				return (
					<TableDetails
						property={property as TablePropertyDto}
						event={event}
						onUpdateProperty={updateProperty}
						setEvent={setEvent}
					/>
				);
			}
			case PropertyDiscriminator.Object: {
				return (
					<ObjectDetails
						property={property as ObjectPropertyDto}
						event={event}
						onUpdateProperty={updateProperty}
						setEvent={setEvent}
					/>
				);
			}
			case PropertyDiscriminator.Calculated: {
				return (
					<CalculatedDetails
						property={property as CalculatedPropertyDto}
						event={event}
						onUpdateProperty={updateProperty}
						setEvent={setEvent}
					/>
				);
			}
		}
		return <></>;
	};

	return (
		<Paper className='details-general-layout'>
			<div className='details-general-content'>
				<Row className='details-general-edit-row'>
					{!editMode && (
						<IconButton className='details-general-edit-button' onClick={onEdit}>
							<FontAwesomeIcon icon={faEdit as IconProp} />
						</IconButton>
					)}
				</Row>
				<Row className='pb-4 pt-4' style={{ alignItems: 'center' }}>
					<Col md={2} className='text-bold'>
						{t('name')}
					</Col>
					<Col md={4}>
						{editMode && tempStatus == Status.Draft ? (
							<TextInput
								width='100%'
								label={t('required')}
								placeholder={t('placeholderInputName')}
								value={name}
								onChange={(value) => setName(value)}
							/>
						) : (
							name
						)}
					</Col>
					<Col md={2} className='text-bold'>
						{t('propertyType')}
					</Col>
					<Col md={4}>{property.propertyDiscriminator}</Col>
				</Row>
				<hr />
				<Row className='pb-4 pt-4' style={{ alignItems: 'center' }}>
					<Col md={2} className='text-bold'>
						{t('description')}
					</Col>
					<Col md={10}>
						{editMode && tempStatus == Status.Draft ? (
							<TextInput
								width='80%'
								label={t('required')}
								placeholder={'Enter Description'}
								value={description}
								onChange={(value) => setDescription(value)}
							/>
						) : (
							description
						)}
					</Col>
				</Row>
				<hr />
				<Row className='pb-4 pt-4' style={{ alignItems: 'center' }}>
					<Col md={1} className='text-bold'>
						{t('status')}
					</Col>
					<Col md={10}>
						<StatusStepper active={editMode} activeStatus={status} setStatus={setStatus} />
					</Col>
				</Row>
				<hr />
				{getExtendenFormByPropertyType()}
			</div>
			<div className='details-general-edit-bottom-row'>
				{ editMode ? 
				(
					<>
						<Button className='p-2' type='secondary' onClick={onCancelEdit} label={t('cancel')} />
						<Button
							className='p-2'
							type='primary'
							disabled={!inputsFilled}
							onClick={onSaveEdit}
							label={t('save')}
							/>
					</>
				)
				: <></>
			
				}
			</div>
		</Paper>
	);
};
export default General;
