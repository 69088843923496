// STYLE
import './PropertyModal.scss';

import { Button as LbcButton } from '@lbc-toolkit/button';
import { Dropdown } from '@lbc-toolkit/dropdown';
import { TextInput } from '@lbc-toolkit/textinput';
import { useTranslate } from '@tolgee/react';
import { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { setCreateMode, setToast } from '../../context/appReducer';
import { useAppDispatch } from '../../context/hooks';
import { setPropertiesNeedReload } from '../../context/propertiesReducer';
import { EventType, Severity } from '../../models/models';
import { PropertiesService, PropertyDiscriminator, PropertyDto } from '../../services/catalog';
import CreateCalculatedPropertyForm from './components/CalculatedForm';
import CreateObjectPropertyForm from './components/ObjectForm';
import CreateSimplePropertyForm from './components/SimpleForm';
import CreateTablePropertyForm from './components/TableForm';

const CreatePropertyContent = () => {
	const { t } = useTranslate();
	const dispatch = useAppDispatch();
	const [childIsValid, setChildIsValid] = useState(true);
	const [event, setEvent] = useState<EventType | null>(null);
	const [wantsToClose, setWantsToClose] = useState<boolean>(false);
	const [inputsFilled, setInputsFilled] = useState<boolean>(false);
	// Input states
	const [name, setName] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const [propertyType, setPropertyType] = useState<PropertyDiscriminator>(PropertyDiscriminator.Simple);

	/**
	 * Updates the control state if every input is filled
	 * Rollbacks if the conditions are not met anymore
	 */
	useEffect(() => {
		setInputsFilled(Boolean(name) && Boolean(description) && childIsValid);
	}, [name, description, setInputsFilled, childIsValid]);

	/**
	 * Fills property with missing fields and sends it to the server for presistant creation
	 * @param property to be created
	 */
	const createProperty = (property: PropertyDto) => {
		property.name = name;
		property.description = description;
		property.propertyDiscriminator = propertyType;
		PropertiesService.createProperties(property)
			.then((_response: any) => {
				resetInputs();
				dispatch(setCreateMode(false));
				dispatch(setPropertiesNeedReload(true));
				dispatch(setToast({ show: true, message: t("createSuccess"), severity: Severity.success }));
			})
			.catch((error: any) => {
				console.log(error.message);
				dispatch(setToast({ show: true, message: t("createFailed"), severity: Severity.warning }));
			});
		setEvent(null);
	};

	/**
	 * Handles creation of property
	 */
	const onCreate = () => {
		setEvent(EventType.CREATE_PROPERTY_EVENT);
	};

	/**
	 * Handles closing of the create modal
	 */
	const onCancel = () => {
		if (name !== '' || description !== null) {
			setWantsToClose(true);
		} else dispatch(setCreateMode(false));
	};

	/**
	 * Resets all input fields to their original state
	 */
	const resetInputs = () => {
		setName('');
		setDescription('');
		setPropertyType(PropertyDiscriminator.Simple);
	};

	/**
	 * Handler for when the close action is aborted. Resets the `wantsToClose` state.
	 */
	const OnAbortClose = () => {
		setWantsToClose(false);
	};

	/**
	 * Handles confirmation of closing
	 */
	const OnConfirmClose = () => {
		dispatch(setCreateMode(false));
		setWantsToClose(false);
		resetInputs();
	};

	const renderForm = () => {
		switch (propertyType) {
			case PropertyDiscriminator.Simple: {
				return (
					<CreateSimplePropertyForm
						resetEvent={() => setEvent(null)}
						onCreateProperty={createProperty}
						event={event}
						onValidationChange={setChildIsValid}
					/>
				);
			}
			case PropertyDiscriminator.Table: {
				return (
					<CreateTablePropertyForm
						resetEvent={() => setEvent(null)}
						onCreateProperty={createProperty}
						event={event}
						onValidationChange={setChildIsValid}
					/>
				);
			}
			case PropertyDiscriminator.Object: {
				return (
					<CreateObjectPropertyForm
						resetEvent={() => setEvent(null)}
						onCreateProperty={createProperty}
						event={event}
						onValidationChange={setChildIsValid}
					/>
				);
			}
			case PropertyDiscriminator.Calculated: {
				return (
					<CreateCalculatedPropertyForm
						resetEvent={() => setEvent(null)}
						onCreateProperty={createProperty}
						event={event}
						onValidationChange={setChildIsValid}
					/>
				);
			}
		}
	};

	return (
		<>
			<Modal.Body className='modal-content-layout'>
				<Row className='pb-4'>
					<Col md={2} className='bold-header'>
						{t('name')}
					</Col>
					<Col md={4}>
						<TextInput
							width='100%'
							label={t('required')}
							placeholder={t('placeholderInputName')}
							onChange={(value) => setName(value)}
						/>
					</Col>
					<Col md={2} className='bold-header'>
						{t('description')}
					</Col>
					<Col md={4}>
						<TextInput
							width='100%'
							label={t('required')}
							placeholder={'Enter Description'}
							onChange={(value) => setDescription(value)}
						/>
					</Col>
				</Row>
				<hr />
				<Row className='pb-4 pt-4'>
					<Col md={2} className='bold-header'>
						{t('propertyType')}
					</Col>
					<Col md={4}>
						<Dropdown
							width='100%'
							label={t('required')}
							onSelect={(value: PropertyDiscriminator) => setPropertyType(value)}
							selected={propertyType}
							options={Object.keys(PropertyDiscriminator)
								.filter((key) => key !== PropertyDiscriminator.Undefined)
								.map((key) => {
									return { label: PropertyDiscriminator[key], value: key };
								})}
						/>
					</Col>
				</Row>
				<hr />
				{renderForm()}
			</Modal.Body>
			<Modal.Footer>
				{wantsToClose ? (
					<>
						<div>{t('info_unsavedDataGetsLost')}</div>
						<LbcButton type='secondary' onClick={OnAbortClose} label={t('no')} />
						<LbcButton type='primary' onClick={OnConfirmClose} label={t('yes')} />
					</>
				) : (
					<>
						<LbcButton type='secondary' onClick={onCancel} label={t('cancel')} />
						<LbcButton type='primary' disabled={!inputsFilled} onClick={onCreate} label={t('save')} />
					</>
				)}
			</Modal.Footer>
		</>
	);
};

export default CreatePropertyContent;
