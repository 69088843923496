import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Col } from 'react-bootstrap';
import { PropertyAssignmentDto, PropertyDto, SimplePropertyDto } from '../../services/catalog';
import './PropertyModal.scss';

interface PropertyAssignmentListProps {
	title: string;
	columnProperties: PropertyAssignmentDto[];
	property: PropertyDto;
}

/**
 * This component is used to display the list of column properties of a table property and properties of a object property.
 */
const PropertyAssignmentList = ({ title, columnProperties }: PropertyAssignmentListProps) => {
	const renderTableBody = () => {
		if (!columnProperties || columnProperties.length === 0) {
			return (
				<TableRow>
					<TableCell colSpan={3} style={{ textAlign: 'center' }}>
						No columns available
					</TableCell>
				</TableRow>
			);
		}

		return columnProperties.map((column, index) => (
			<TableRow key={index}>
				<TableCell>{column.name}</TableCell>
				<TableCell>{column.property ? column.property.name : ''}</TableCell>
				<TableCell>{column.property ? (column.property as SimplePropertyDto).dataType : ''}</TableCell>
			</TableRow>
		));
	};

	return (
		<>
			<Col md={2} className='text-bold'>
				{title}
			</Col>

			<Col md={10}>
				<div className='property-table-container'>
					<Table stickyHeader size='small'>
						<TableHead>
							<TableRow>
								<TableCell className='bold-header'>Name</TableCell>
								<TableCell className='bold-header'>Property</TableCell>
								<TableCell className='bold-header'>Datatype</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>{renderTableBody()}</TableBody>
					</Table>
				</div>
			</Col>
		</>
	);
};

export default PropertyAssignmentList;
