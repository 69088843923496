// STYLING
import '../../styling/Modal.scss';

import { useTranslate } from '@tolgee/react';
import { Modal } from 'react-bootstrap';
import { useAppSelector } from '../../context/hooks';
import { RootState } from '../../context/store';
import CreateClassContent from '../CreateClassificationModal/CreateClassContent';
import CreatePropertyContent from '../CreatePropertyModal/CreatePropertyContent';

const CreateModal = () => {
	const { t } = useTranslate();
	const activeTab = useAppSelector((state: RootState) => state.app.activeTab);
	const createMode = useAppSelector((state: RootState) => state.app.createMode);
	const content: JSX.Element = activeTab === 'classes' ? <CreateClassContent /> : <CreatePropertyContent />;

	return (
		<Modal dialogClassName='create-modal-layout' centered show={createMode}>
			<Modal.Header>
				<Modal.Title>{t('createNew')}</Modal.Title>
			</Modal.Header>
			{content}
		</Modal>
	);
};

export default CreateModal;
