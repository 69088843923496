// REACT
import { useEffect, useState } from 'react';

// MODELS & SERVICES


// STYLE
import '../PropertyModal.scss';
import { EventType } from '../../../models/models';
import PropertyAssignments from '../../../components/PropertyAssignment/PropertyAssignments';
import { PropertyDto, PropertyDiscriminator, PropertyAssignmentDto } from '../../../services/catalog';

interface Props {
	event: EventType | null;
	onCreateProperty: (property: PropertyDto) => void;
	resetEvent: () => void;
	onValidationChange: (isValid: boolean) => void;
}

const CreateTablePropertyForm = ({ event, onCreateProperty, resetEvent, onValidationChange }: Props) => {
	const [assignedProperties, setAssignedProperties] = useState<PropertyAssignmentDto[]>([]);
	
	useEffect(() => {
		onValidationChange(true);
	}, [onValidationChange]);

	return (
		<PropertyAssignments
			property={undefined}
			title='Columns'
			event={event}
			propertyType={PropertyDiscriminator.Table}
			assignedProperties={assignedProperties}
			setAssignedProperties={setAssignedProperties}
			onCreateProperty={onCreateProperty}
			resetEvent={resetEvent}
		/>
	);
};
export default CreateTablePropertyForm;
