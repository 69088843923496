// STYLING
import '../../styling/Modal.scss';

import { Button } from '@lbc-toolkit/button';
import { TextInput } from '@lbc-toolkit/textinput';
import { useTranslate } from '@tolgee/react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { setDeleteMode, setToast } from '../../context/appReducer';
import { setClassTreeNeedsReload, setCurrentClass } from '../../context/classesReducer';
import { useAppDispatch, useAppSelector } from '../../context/hooks';
import { removeFromProperties, setCurrentProperty } from '../../context/propertiesReducer';
import { RootState } from '../../context/store';
import { removeFromTableProperties } from '../../context/tablePropertiesReducer';
import { Severity } from '../../models/models';
import { ClassificationsService, PropertiesService } from '../../services/catalog';

const DeleteModal = () => {
	const { t } = useTranslate();
	const dispatch = useAppDispatch();
	const activeTab = useAppSelector((state: RootState) => state.app.activeTab);
	const deleteMode = useAppSelector((state: RootState) => state.app.deleteMode);
	const currentClass = useAppSelector((state: RootState) => state.classes.currentClass);
	const currentProperty = useAppSelector((state: RootState) => state.properties.currentProperty);
	const currentTableProperty = useAppSelector((state: RootState) => state.TableProperties.currentTableProperty);
	const [deleteConfirmed, setDeleteConfirmed] = useState<boolean>(false);

	/**
	 * Resets the delete modal
	 */
	const ResetDeleteModal = () => {
		dispatch(setCurrentClass(null));
		dispatch(setCurrentProperty(null));
		dispatch(setDeleteMode(false));
	};

	/**
	 * Handles closing the delete modal
	 */
	const OnCancel = () => {
		ResetDeleteModal();
	};

	/**
	 * Manages the deletion process based on the active tab.
	 * If the active tab is 'classes', it will delete a class.
	 * Otherwise, it will decide to delete a Table property or a regular property
	 * based on the truthiness of `currentTableProperty`.
	 */
	const OnDelete = () => {
		if (activeTab === 'classes') {
			DeleteClass();
		} else {
			currentTableProperty ? deleteTableProperty() : DeleteProperty();
		}
	};

	/**
	 * Deletes a class
	 */
	const DeleteClass = () => {
		ClassificationsService.deleteClassification(currentClass?.id!)
			.then(() => {
				dispatch(
					setToast({
						show: true,
						severity: Severity.success,
						message: t('deleteClassSuccess'),
					}),
				);
				dispatch(setDeleteMode(false));
			})
			.catch((error: any) => {
				console.log(error.message);
				dispatch(
					setToast({
						show: true,
						severity: Severity.alert,
						message: t('deleteFailed'),
					}),
				);
			})
			.finally(() => {
				ResetDeleteModal();
				dispatch(setClassTreeNeedsReload(true));
			});
	};

	/**
	 * Deletes a property
	 */
	const DeleteProperty = () => {
		PropertiesService.deleteProperty(currentProperty?.id!)
			.then(() => {
				dispatch(
					setToast({
						show: true,
						severity: Severity.success,
						message: t('deletePropertySuccess'),
					}),
				);
				dispatch(removeFromProperties(currentProperty!));
				dispatch(setDeleteMode(false));
			})
			.catch((error: any) => {
				console.log(error.message);
				dispatch(
					setToast({
						show: true,
						severity: Severity.alert,
						message: t('deletePropertyFailed'),
					}),
				);
			})
			.finally(() => {
				ResetDeleteModal();
			});
	};

	/**
	 * Deletes a Table property
	 */
	const deleteTableProperty = () => {
		PropertiesService.deleteProperty(currentTableProperty?.id!)
			.then(() => {
				dispatch(
					setToast({
						show: true,
						severity: Severity.success,
						message: t('deleteTablePropertySuccess'),
					}),
				);
				dispatch(removeFromTableProperties(currentTableProperty!));
				dispatch(setDeleteMode(false));
			})
			.catch((error: any) => {
				console.log(error.message);
				dispatch(
					setToast({
						show: true,
						severity: Severity.alert,
						message: t('deleteTablePropertyFailed'),
					}),
				);
			})
			.finally(() => {
				ResetDeleteModal();
			});
	};

	return (
		<Modal dialogClassName='delete-modal-layout' centered show={deleteMode}>
			<Modal.Header>
				<Modal.Title>{t('delete')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					{t('toConfirmType') + ' '}
					{activeTab === 'classes' ? currentClass?.name : currentTableProperty?.name || currentProperty?.name}
				</div>
				<TextInput
					width='100%'
					className='row-inputField'
					label={t('confirmDeleteLabel')}
					onChange={(value: string) => {
						value ===
						(activeTab === 'classes'
							? currentClass?.name
							: currentTableProperty?.name || currentProperty?.name)
							? setDeleteConfirmed(true)
							: setDeleteConfirmed(false);
					}}
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button type='secondary' onClick={OnCancel} label={t('cancel')} />
				<Button disabled={!deleteConfirmed} type='primary' onClick={OnDelete} label={t('delete')} />
			</Modal.Footer>
		</Modal>
	);
};

export default DeleteModal;
