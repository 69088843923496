import { Dropdown } from '@lbc-toolkit/dropdown';
import { useTranslate } from '@tolgee/react';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { EventType } from '../../../models/models';
import { DataType, PropertyDto } from '../../../services/catalog';
import CreateBasicPropertyForm from './BasicForm';
import CreateUnitPropertyForm from './UnitForm';

interface Props {
	event: EventType | null;
	onCreateProperty: (property: PropertyDto) => void;
	resetEvent: () => void;
	onValidationChange: (isValid: boolean) => void;
}

/**
 * Provide the part of the form to fill all specific information
 * that are used in simple properties.
 */
const CreateSimplePropertyForm = ({ event, onCreateProperty, resetEvent, onValidationChange }: Props) => {
	const { t } = useTranslate();
	const [datatype, setDatatype] = useState<DataType>(DataType.String);

	/**
	 * Render the form depending on the selected datatype.
	 */
	function renderForm() {
		switch (datatype) {
			case DataType.Integer:
			case DataType.Double: {
				return (
					<CreateUnitPropertyForm
						resetEvent={resetEvent}
						dataType={datatype}
						onCreateProperty={onCreateProperty}
						event={event}
						onValidationChange={onValidationChange}
					/>
				);
			}
			case DataType.Boolean:
			case DataType.String: {
				return (
					<CreateBasicPropertyForm
						resetEvent={resetEvent}
						dataType={datatype}
						onCreateProperty={onCreateProperty}
						event={event}
						onValidationChange={onValidationChange}
					/>
				);
			}
			default: {
				return <div />;
			}
		}
	}

	return (
		<>
			<Row className='pb-4 pt-4'>
				<Col md={2} className='bold-header'>
					{t('dataType')}
				</Col>
				<Col md={4}>
					<Dropdown
						width='100%'
						label={t('required')}
						onSelect={(value: DataType) => setDatatype(value)}
						selected={datatype}
						options={Object.keys(DataType).map((key) => {
							return { label: DataType[key], value: key };
						})}
					/>
				</Col>
			</Row>
			<hr />
			{renderForm()}
		</>
	);
};

export default CreateSimplePropertyForm;
