import { Dropdown } from '@lbc-toolkit/dropdown';
import { Text } from '@lbc-toolkit/text';
import { TextArea } from '@lbc-toolkit/textarea';
import { useTranslate } from '@tolgee/react';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAppSelector } from '../../../../../context/hooks';
import { RootState } from '../../../../../context/store';
import { PropertyDto, PropertySetDto, PropertyUsageType } from '../../../../../services/catalog';

interface Props {
	usage: string;
	propertyType: PropertyUsageType;
	setUsage: (number) => void;
	setPropertyType: (propertyUsageType: PropertyUsageType) => void;
	setBlockNextStep: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectableConfiguration = ({ usage, propertyType, setUsage, setPropertyType, setBlockNextStep }: Props) => {
	const { t } = useTranslate();
	const propertySets: PropertySetDto[] = useAppSelector((state: RootState) => state.properties.propertySets);
	const selectedProperty: PropertyDto | null = useAppSelector((state: RootState) => state.mappings.selectedProperty);
	const selectedPropertySet: string | null = useAppSelector((state: RootState) => state.mappings.selectedPropertySet);

	useEffect(() => {
		if (usage != '') {
			setBlockNextStep(false);
		} else {
			setBlockNextStep(true);
		}
	}, [usage]);

	return (
		<>
			<Text type='h6' className='mb-2'>
				{t('configureMapping')}
			</Text>
			<div className='fullheight'>
				<Row className='py-2 border-bottom'>
					<Col>{t('property')}</Col>
					<Col>{selectedProperty?.name}</Col>
				</Row>
				<Row className='py-2 border-bottom'>
					<Col>{t('propertySet')}</Col>
					<Col>
						{selectedProperty?.propertySetName ??
							propertySets.find((x) => x.id === selectedPropertySet)?.name ??
							''}
					</Col>
				</Row>
				<Row className='py-2 border-bottom'>
					<Col>{t('propertyType')}</Col>
					<Col>
						<Dropdown
							width='100%'
							options={Object.keys(PropertyUsageType).map((key) => {
								return { label: PropertyUsageType[key], value: key };
							})}
							onSelect={(value: PropertyUsageType) => setPropertyType(value)}
							selected={propertyType}
						/>
					</Col>
				</Row>
				<Row className='py-2'>
					<Col>{t('usage')}</Col>
					<Col>
						<TextArea
							width='100%'
							label={t('required')}
							placeholder={t('placeholder_usageOfMapping')}
							value={usage}
							onChange={(value: string) => {
								setUsage(value);
							}}
						/>
						<div
							className={
								usage.length > 255 ? 'modal-content-usage-length-tooLong' : 'modal-content-usage-length'
							}
						>
							{usage.length > 255
								? t('tooManyCharacters') + usage.length + '/255'
								: usage.length + '/255'}
						</div>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default SelectableConfiguration;
