/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalculatedPropertyDto } from '../models/CalculatedPropertyDto';
import type { ObjectPropertyDto } from '../models/ObjectPropertyDto';
import type { PropertyDto } from '../models/PropertyDto';
import type { SimplePropertyDto } from '../models/SimplePropertyDto';
import type { TablePropertyDto } from '../models/TablePropertyDto';
import type { UnitTypeDto } from '../models/UnitTypeDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PropertiesService {

    /**
     * Endpoint to create a new property.
     * @param requestBody Data the property will be created with.
     * @returns any Success
     * @throws ApiError
     */
    public static createProperties(
requestBody?: (PropertyDto | CalculatedPropertyDto | ObjectPropertyDto | SimplePropertyDto | TablePropertyDto),
): CancelablePromise<(PropertyDto | CalculatedPropertyDto | ObjectPropertyDto | SimplePropertyDto | TablePropertyDto)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/properties',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Endpoint to get every property.
     * @returns any Success
     * @throws ApiError
     */
    public static getProperties(): CancelablePromise<Array<(PropertyDto | CalculatedPropertyDto | ObjectPropertyDto | SimplePropertyDto | TablePropertyDto)>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/properties',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Endpoint to update a specific property.
     * @param requestBody Data, the property will be searched and updated with.
     * @returns any Success
     * @throws ApiError
     */
    public static updateProperty(
requestBody?: (PropertyDto | CalculatedPropertyDto | ObjectPropertyDto | SimplePropertyDto | TablePropertyDto),
): CancelablePromise<(PropertyDto | CalculatedPropertyDto | ObjectPropertyDto | SimplePropertyDto | TablePropertyDto)> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/properties',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Endpoint to get a property with a given id.
     * @param id Id to search for a property.
     * @returns any Success
     * @throws ApiError
     */
    public static getProperty(
id: string,
): CancelablePromise<(PropertyDto | CalculatedPropertyDto | ObjectPropertyDto | SimplePropertyDto | TablePropertyDto)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/properties/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Endpoint to delete a specific property.
     * @param id Id of the property.
     * @returns void 
     * @throws ApiError
     */
    public static deleteProperty(
id: string,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/properties/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Endpoint to get every property that has a specific unittype.
     * @param unitType 
     * @returns SimplePropertyDto Success
     * @throws ApiError
     */
    public static getPropertiesByUnitType(
unitType: string,
): CancelablePromise<Array<SimplePropertyDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/properties/unittype/{unitType}',
            path: {
                'unitType': unitType,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Endpoint to get every property.
     * @returns UnitTypeDto Success
     * @throws ApiError
     */
    public static getUnitTypes(): CancelablePromise<Array<UnitTypeDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/properties/unittypes',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Endpoint to validate a formula.
     * @param requestBody The formula to validate.
     * @returns boolean Success
     * @throws ApiError
     */
    public static validateFormula(
requestBody?: string,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/properties/formula/validate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
