import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faRotateRight, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from '@lbc-toolkit/dropdown';
import { Spinner } from '@lbc-toolkit/spinner';
import { useTranslate } from '@tolgee/react';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { EventType } from '../../../models/models';
import {
	CalculatedPropertyDto,
	PropertiesService,
	PropertyDiscriminator,
	PropertyDto,
	UnitDto,
	UnitTypeDto,
} from '../../../services/catalog';
import FormulaCalculator from '../../FormulaCalculator/FormulaCalculator';
import '../../FormulaCalculator/FormulaCalculator.scss';

interface Props {
	event: EventType | null;
	onCreateProperty: (property: PropertyDto) => void;
	resetEvent: () => void;
	onValidationChange: (isValid: boolean) => void;
}

const CreateCalculatedPropertyForm = ({ event, onCreateProperty, resetEvent }: Props) => {
	const { t } = useTranslate();
	const [unittypes, setUnittypes] = useState<UnitTypeDto[]>([]);
	const [isUnitTypeLoadingFailed, setUnitTypeLoadingFailed] = useState<boolean>(false);
	const [isLoadingUnitTypes, setLoadingUnitTypes] = useState<boolean>(false);
	const [unitType, setUnitType] = useState<UnitTypeDto | null>(null);
	const [unit, setUnit] = useState<UnitDto | null>(null);
	const [formula, setFormula] = useState<string>('');

	useEffect(() => {
		const buildTableProperty = () => {
			try {
				const property: CalculatedPropertyDto = {
					propertyDiscriminator: PropertyDiscriminator.Calculated,
					unittype: unitType?.name || null,
					unit: unit?.name || null,
					formula: formula,
				};
				onCreateProperty(property);
			} catch (error: any) {
				console.error(error.message);
				resetEvent();
			}
		};
		if (event === EventType.CREATE_PROPERTY_EVENT) {
			buildTableProperty();
		}
	}, [event, unitType, unit, formula, onCreateProperty, resetEvent]);

	useEffect(() => {
		const loadUnitTypes = async () => {
			setLoadingUnitTypes(true);
			try {
				const retrievedUnittypes: UnitTypeDto[] = await PropertiesService.getUnitTypes();
				setUnittypes(retrievedUnittypes);
				setUnitTypeLoadingFailed(false);
			} catch (error: any) {
				setUnitTypeLoadingFailed(true);
			} finally {
				setLoadingUnitTypes(false);
			}
		};
		loadUnitTypes();
	}, []);

	const handleFormulaChange = (newFormula: string) => {
		setFormula(newFormula);
	};

	return (
		<>
			<Row className='pb-4 pt-4'>
				<Col md={2} className='bold-header'>
					{t('unitType')}
				</Col>
				<Col md={4}>
					{isUnitTypeLoadingFailed && !isLoadingUnitTypes && (
						<div className='unittype-error-message'>
							<FontAwesomeIcon className='error pe-1' size='sm' icon={faWarning as IconProp} />
							<div className='error'>{t('loadingFailed')}</div>
							<FontAwesomeIcon className='reload ps-1' icon={faRotateRight as IconProp} />
						</div>
					)}
					{isLoadingUnitTypes && (
						<div>
							<Spinner spin className='pe-1' />
							{t('load')}
						</div>
					)}
					<Dropdown
						width='100%'
						label={t('required')}
						disabled={isUnitTypeLoadingFailed || isLoadingUnitTypes}
						placeholder={t('placeholder_inputUnitType')}
						onSelect={(value: UnitTypeDto) => setUnitType(value)}
						selected={unitType}
						options={unittypes.map((ut: UnitTypeDto) => ({ label: ut.name, value: ut }))}
						isClearable
					/>
				</Col>
				<Col md={2} className='bold-header'>
					{t('unit')}
				</Col>
				<Col md={4}>
					<Dropdown
						width='100%'
						label={t('required')}
						placeholder={t('placeholder_inputUnit')}
						onSelect={(value: UnitDto) => setUnit(value)}
						selected={unit}
						options={unitType?.units?.map((u: UnitDto) => ({ label: u.name, value: u }))}
						disabled={!Boolean(unitType)}
					/>
				</Col>
			</Row>
			<hr />
			<Row className='pb-4 pt-4'>
				<Col md={2} className='bold-header'>
					{t('formula')}
				</Col>
				<Col md={10}>
					<FormulaCalculator initialFormula={formula} onFormulaChange={handleFormulaChange} />
				</Col>
			</Row>
		</>
	);
};

export default CreateCalculatedPropertyForm;
