import Paper from '@mui/material/Paper';
import { useTranslate } from '@tolgee/react';
import { useState } from 'react';
import PropertyUpdateStatusStepperWithButtons from '../../../../../components/navigation/stepper/PropertyUpdateStatusStepper';
import { setToast } from '../../../../../context/appReducer';
import { useAppDispatch, useAppSelector } from '../../../../../context/hooks';
import { setAddMappingMode, setMappingsListNeedsReload, setSelectedProperty, setSelectedPropertySet } from '../../../../../context/mappingsReducer';
import { addToProperties, removeFromProperties } from '../../../../../context/propertiesReducer';
import { RootState } from '../../../../../context/store';
import { Severity } from '../../../../../models/models';
import { ClassificationDto, CreateMappingDto, MappingsService, PropertyDto, PropertySetDto, PropertyUsageType } from '../../../../../services/catalog';
import ContentItem from './ContentItem';
import SelectableConfiguration from './SelectableConfiguration';
import SelectableProperties from './SelectableProperties';
import SelectablePropertySets from './SelectablePropertySets';

const MappingConfigurator = () => {
	const [currentStep, setCurrentStep] = useState<number>(0);
	const [blockNextStep, setBlockNextStep] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	const { t } = useTranslate();
	const [usage, setUsage] = useState<string>('');
	const currentClass: ClassificationDto | null = useAppSelector((state: RootState) => state.classes.currentClass);
	const [propertyType, setPropertyType] = useState<PropertyUsageType>(PropertyUsageType.Type);
	const propertySets: PropertySetDto[] = useAppSelector((state: RootState) => state.properties.propertySets);
	const selectableConf: ContentItem = {
		content: (
			<SelectableConfiguration
				usage={usage}
				propertyType={propertyType}
				setUsage={setUsage}
				setPropertyType={(propertyUsageType: PropertyUsageType) => setPropertyType(propertyUsageType)}
				setBlockNextStep={setBlockNextStep}
			/>
		),
		title: 'selectable conf',
	};
	const selectablePropSets: ContentItem = {
		content: <SelectablePropertySets setBlockNextStep={setBlockNextStep} />,
		title: 'selectable propsets',
	};
	const selectableProps: ContentItem = { content: <SelectableProperties setBlockNextStep={setBlockNextStep} />, title: 'selectable props' };
	const selectedProperty: PropertyDto | null = useAppSelector((state: RootState) => state.mappings.selectedProperty);
	const selectedPropertySet: string | null = useAppSelector((state: RootState) => state.mappings.selectedPropertySet);
	const contentItems: ContentItem[] = [selectableProps, selectablePropSets, selectableConf];
	/**
	 * Handles the reset of all input fields and states
	 */
	const ResetInputs = () => {
		dispatch(setAddMappingMode(false));
		dispatch(setSelectedProperty(null));
		dispatch(setSelectedPropertySet(null));
	};

	const RenderStepContent = () => {
		return contentItems[currentStep].content;
	};

	const OnSave = () => {
		if (usage === '') {
			dispatch(
				setToast({
					show: true,
					severity: Severity.warning,
					message: t('info_inputfieldsAreEmpty'),
				}),
			);
			return;
		}

		let updatedProperty: PropertyDto = {
			...selectedProperty,
			propertySetId: selectedPropertySet ?? selectedProperty?.propertySetId ?? undefined,
			propertySetName: propertySets.find((x) => x.id === selectedPropertySet)?.name ?? '',
		};
		dispatch(setSelectedProperty(updatedProperty));
		dispatch(removeFromProperties(selectedProperty!));

		const mapping: CreateMappingDto = {
			propertyUsageType: propertyType,
			usage: usage,
			propertySetId: selectedPropertySet ?? selectedProperty?.propertySetId ?? undefined,
		};

		MappingsService.addMapping(currentClass?.id ?? '', selectedProperty?.id ?? '', mapping)
			.then(() => {
				dispatch(
					setToast({
						show: true,
						severity: Severity.success,
						message: t('createMappingSuccess'),
					}),
				);
			})
			.catch(() => {
				dispatch(
					setToast({
						show: true,
						severity: Severity.alert,
						message: t('createFailed'),
					}),
				);
			})
			.finally(() => {
				ResetInputs();
				dispatch(addToProperties(updatedProperty));
				dispatch(setSelectedProperty(updatedProperty));
				dispatch(setMappingsListNeedsReload(true));
			});
	};
	/**
	 * Handles the navigation between the different mapping configuration steps
	 */
	const OnCancel = () => {
		dispatch(setSelectedProperty(null));
		dispatch(setAddMappingMode(false));
	};

	return (
		<Paper className='paper-selectables-layout justify-content-space-between-vertical' style={{ width: '200%' }}>
			<div>{RenderStepContent()}</div>
			<div className='justify-content-space-between-horizontal border-top'>
				<PropertyUpdateStatusStepperWithButtons
					currentStep={currentStep}
					maxSteps={2}
					onSave={OnSave}
					onCancel={OnCancel}
					updateStep={setCurrentStep}
					blockNextStep={blockNextStep}
				/>
			</div>
		</Paper>
	);
};

export default MappingConfigurator;
