import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBadgeCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CircularProgress, Stack, TextField, Tooltip } from "@mui/material";
import { UseMutateFunction } from "@tanstack/react-query";
import { useTranslate } from "@tolgee/react";
import { Dispatch, SetStateAction } from "react";


interface Props {
    formula: string;
    setFormula: Dispatch<SetStateAction<string>>;
    fetchValidateFormula: UseMutateFunction<boolean, Error, void, unknown>;
    isValid: boolean | undefined;
    isValidatingPending: boolean;
    setIsValid: Dispatch<SetStateAction<boolean | undefined>>;
}

const FormulaTextField = ({ formula, setFormula, fetchValidateFormula, isValid, isValidatingPending, setIsValid }: Props) => {
    const { t } = useTranslate();

    return (
        <Stack direction='row' className='mb-4'>
            <TextField
                className='formularEditor-formularField'
                value={formula}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFormula(event.target.value) 
                    setIsValid(undefined);
                }}
            />
            <Tooltip title={t('validateFormula')} arrow>
                <Button
                    className='formularEditor-calculator-button border-right-1 border-top-1 border-bottom-1 border-radius-0'
                    style={{ height: '56px', width: '56px', backgroundColor: isValid === undefined ? 'transparent' : isValid ? 'green' : 'red'  }}
                    onClick={() => {fetchValidateFormula()}}
                    disabled={isValidatingPending}
                    >
                        {isValidatingPending ?
                            <CircularProgress style={{ color: 'black' }} />
                            :
                            <FontAwesomeIcon
                            size="lg"
                            id='calculatorBtn'
                            color={isValid === undefined ? 'black' : 'white'}
                            icon={(faBadgeCheck as IconProp)}
                            />
                        }
                </Button>
            </Tooltip>
        </Stack>
    );
}

export default FormulaTextField;