import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { PropertiesService } from "../../../services/catalog";
import { setToast } from "../../../context/appReducer";
import { Severity } from "../../../models/models";
import { useAppDispatch } from "../../../context/hooks";

const useFormulaCalculator = (initialFormula: string) => {
    const [formula, setFormula] = useState<string>(initialFormula);
    const [isValid, setIsValid] = useState<boolean | undefined>(undefined);

    const dispatch = useAppDispatch();

    useEffect(() => {
        setIsValid(undefined);
    }, [formula]);

    const { mutate: fetchValidateFormula, isPending: isValidatingPending } = useMutation({
        mutationKey: ['formulaValidation'],
        mutationFn: () => PropertiesService.validateFormula(formula),
        onSuccess: (data) => {
            setIsValid(data);
        },
        onError: () => {
            setIsValid(false);
            dispatch(setToast({ message: 'Error validating formula', severity: Severity.alert, show: true }));
        }
    });

    const addTerm = (term: string) => {
        setFormula(formula + term);
    }

    return {
        formula,
        setFormula,
        isValid,
        setIsValid,
        isValidatingPending,
        fetchValidateFormula,
        addTerm,
    };
};

export default useFormulaCalculator;
