// STYLING
import '../../../../styling/Details.scss';

import { useAppSelector } from '../../../../context/hooks';
import { RootState } from '../../../../context/store';
import MappingConfigurator from './MappingConfiguration/MappingConfigurator';
import MappingsList from './MappingsList';

const ClassDetailsPropertiesModalContent = () => {
	const addMappingMode = useAppSelector((state: RootState) => state.mappings.addMappingMode);

	return (
		<div className='details-class-properties-layout'>{addMappingMode ? <MappingConfigurator /> : <MappingsList />}</div>
	);
};

export default ClassDetailsPropertiesModalContent;
