import { Navigate, useLocation } from "react-router-dom";
import useKeycloak from "../hooks/useKeycloak";

export const PublicRoute = ({ children }) => {
	const { isInitialized } = useKeycloak();
	const location = useLocation();

	if (isInitialized) {
		if (false) {
			return location.pathname === '/unauthorized' ? <Navigate to='/' /> : children;
		}
	}

	return children;
};
