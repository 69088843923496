import { Button, Stack } from '@mui/material';
import CountSelector from './components/CountSelector';
import PropertySelector from './components/PropertySelector';
import SumSelector from './components/SumSelector';

interface Props {
	addTerm: (term: string) => void;
	formulaSelectType: string;
	setFormulaSelectType: (type: string) => void;
}

const CalculatorExtension = ({ addTerm, formulaSelectType, setFormulaSelectType }: Props) => {
	return (
		<Stack direction='column' justifyContent='flex-start' className='border-1 flex-grow'>
			<Stack direction='row' justifyContent='space-between' className='border-bottom-1 mb-2'>
				<Button
					className={`formulaEditor-action-button-y border-right-1 flex-grow ${formulaSelectType === 'Prop' ? 'formulaEditor-action-button-selected' : ''}`}
					onClick={() => setFormulaSelectType('Prop')}
				>
					Prop
				</Button>
				<Button
					className={`formulaEditor-action-button-y border-right-1 flex-grow ${formulaSelectType === 'Count' ? 'formulaEditor-action-button-selected' : ''}`}
					onClick={() => setFormulaSelectType('Count')}
				>
					Count
				</Button>
				<Button
					className={`formulaEditor-action-button-y flex-grow ${formulaSelectType === 'Sum' ? 'formulaEditor-action-button-selected' : ''}`}
					onClick={() => setFormulaSelectType('Sum')}
				>
					Sum
				</Button>
			</Stack>
			<Stack direction='column' justifyContent='space-between' className='pa-1 flex-grow'>
				{formulaSelectType === 'Prop' ? <PropertySelector addTerm={addTerm} /> : <></>}
				{formulaSelectType === 'Count' ? <CountSelector addTerm={addTerm} /> : <></>}
				{formulaSelectType === 'Sum' ? <SumSelector addTerm={addTerm} /> : <></>}
			</Stack>
		</Stack>
	);
};

export default CalculatorExtension;
