import { Button, Stack } from '@mui/material';
import { useState } from 'react';
import useClassifications from '../../../../../hooks/useClassifications';
import useProperties from '../../../../../hooks/useProperties';
import { ClassificationDto, PropertyDiscriminator, SimplePropertyDto } from '../../../../../services/catalog';
import Selection from '../../../components/Selection';

type Props = {
	addTerm: (term: string) => void;
};

const SumSelector = ({ addTerm }: Props) => {
	const [selectedClassification, setSelectedClassification] = useState<ClassificationDto | null>(null);
	const [selectedProperty, setSelectedProperty] = useState<SimplePropertyDto | null>(null);

	const { classifications } = useClassifications();
	const { properties } = useProperties();

	const OnAdd = () => {
		if (selectedProperty === null) return;
		addTerm('$sum{' + (selectedClassification?.name ?? '*') + '.' + (selectedProperty?.name ?? '*') + '}');
		setSelectedClassification(null);
		setSelectedProperty(null);
	};

	return (
		<>
			<Stack direction='column'>
				<Selection
					selectables={classifications ?? []}
					selected={selectedClassification}
					setSelected={setSelectedClassification}
					label='Select a Classification'
					nullable
				/>
				<Selection
					selectables={
						properties?.filter(
							(p) =>
								p.propertyDiscriminator == PropertyDiscriminator.Simple &&
								((p as SimplePropertyDto).dataType === 'Double' ||
									(p as SimplePropertyDto).dataType === 'Integer'),
						) ?? []
					}
					selected={selectedProperty}
					setSelected={setSelectedProperty}
					label='Select a Property'
				/>
			</Stack>
			<Stack direction='row-reverse' gap={1}>
				<Button className='formulaEditor-action-button-y' onClick={() => OnAdd()}>
					Add
				</Button>
			</Stack>
		</>
	);
};

export default SumSelector;
