import { InputLabel, Select, MenuItem, Stack, FormControl, Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { PropertiesService, PropertyDiscriminator, SimplePropertyDto } from "../../../../../services/catalog";

type Props = {
    addTerm: (term: string) => void;
    selectedUnitType?: string;
}

const PropertySelector = ({ addTerm, selectedUnitType }: Props) => {
    const [selectedProperty, setSelectedProperty] = useState<SimplePropertyDto | null>(null);
    const { data: properties } = useQuery({
		queryKey: ['properties', selectedUnitType],
		queryFn: () => {
            if(selectedUnitType) {
                return PropertiesService.getPropertiesByUnitType(selectedUnitType);
            }
        },
	});

    const onAdd = () => {
        if(selectedProperty) {
            addTerm("${" + selectedProperty.name! + "}")
            setSelectedProperty(null);
        }
    }

    return (
        <>
            <Stack direction='column'>
                <FormControl>
                    <InputLabel>Select a property</InputLabel>
                    <Select
                        label='Property'
                        variant='standard'
                        className='mb-2'
                        value={selectedProperty?.id ?? ""}
                        onChange={(e) => setSelectedProperty(properties?.find((p) => p.id === e.target.value) ?? null)}
                        >
                        {properties?.filter((p) => p.propertyDiscriminator == PropertyDiscriminator.Simple).map((property) => (
                            <MenuItem key={property.id} value={property.id ?? ""}>
                                {property.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
            <Stack direction='row-reverse' gap={1}>
                <Button className='formulaEditor-action-button-y' onClick={() => onAdd()}>Add</Button>
            </Stack>
        </>
    );
}

export default PropertySelector;