import { InputLabel, Select, MenuItem, Stack, FormControl, Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { ClassificationDto, SimplePropertyDto, ClassificationsService, PropertiesService, MappingsService, PropertyDiscriminator, DataType } from "../../../../../services/catalog";

type Props = {
    addTerm: (term: string) => void;
    selectedUnitType?: string;
}

const CountSelector = ({ addTerm, selectedUnitType }: Props) => {
    const [selectedClassification, setSelectedClassification] = useState<ClassificationDto | null>(null);
    const [selectedProperty, setSelectedProperty] = useState<SimplePropertyDto | null>(null);

    const { data: classifications } = useQuery({
        queryKey: ['classifications'],
        queryFn: () => ClassificationsService.getAllClassifications(),
    });

    const { data: properties, refetch: refetchProperties } = useQuery({
        queryKey: ['properties'],
        queryFn: () => selectedClassification == null
            ? PropertiesService.getPropertiesByUnitType(selectedUnitType ?? "")
            : MappingsService.getMappingsByClass(selectedClassification.id ?? '')
                .then((mappings) => 
                    mappings.filter((m) => {
                        var property = m.property?.propertyDiscriminator === PropertyDiscriminator.Simple ? m.property as SimplePropertyDto : null;
                        return property !== null && property.unittype === selectedUnitType && property.unit && (property.dataType === DataType.Integer || property.dataType === DataType.Double);
                    }
                    ).map((m) => m.property)),
    });

    useEffect(() => {
        if(selectedClassification || selectedUnitType) {
            refetchProperties();
        }
    }, [selectedClassification, selectedUnitType, refetchProperties])

    const OnAdd = () => {
        if(!selectedProperty && !selectedClassification) return;
        addTerm("$count{" + (selectedClassification?.name ?? "*") + "." + (selectedProperty?.name ?? "*") + "}");
        setSelectedClassification(null);
        setSelectedProperty(null);
    }

    return (
        <>
            <Stack direction='column'>
                <FormControl>
                    <InputLabel>Select a classification</InputLabel>
                    <Select
                        label='Classification'
                        variant='standard'
                        className='mb-2 ml-1'
                        value={selectedClassification?.id ?? '*'}
                        onChange={(e) => {
                            const selected = classifications?.find((c) => c.id === e.target.value) ?? null;
                            setSelectedClassification(selected);
                            setSelectedProperty(null);
                        }}
                        >
                        <MenuItem value="*">*</MenuItem>
                        {classifications?.map((classification) => (
                            <MenuItem key={classification.id} value={classification.id ?? ""}>
                                {classification.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel>Select a property</InputLabel>
                    <Select
                        label='Property'
                        variant='standard'
                        className='mb-2 ml-1'
                        value={selectedProperty?.id ?? '*'}
                        onChange={(e) => setSelectedProperty(properties?.find((p) => p?.id === e.target.value) ?? null)}
                        >
                        <MenuItem value="*">*</MenuItem>
                        {properties?.filter((p) => p?.propertyDiscriminator == PropertyDiscriminator.Simple).map((property) => (
                            <MenuItem key={property?.id} value={property?.id ?? ""}>
                                {property?.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
            <Stack direction='row-reverse' gap={1}>
                <Button className='formulaEditor-action-button-y' onClick={() => OnAdd()}>Add</Button>
            </Stack>
        </>
    );
}

export default CountSelector;