import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { EventType, Severity } from '../../../../models/models';
import { CalculatedPropertyDto, PropertiesService, PropertyDto, Status, UnitDto, UnitTypeDto } from '../../../../services/catalog';
import { useAppDispatch } from '../../../../context/hooks';
import { useTranslate } from '@tolgee/react';
import { Spinner } from '@lbc-toolkit/spinner';
import { Dropdown } from '@lbc-toolkit/dropdown';
import FormulaCalculator from '../../../FormulaCalculator/FormulaCalculator';
import { setToast } from '../../../../context/appReducer';
import '../../styles/PropertyModal.scss';
import { useQuery } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faRotateRight, faWarning } from '@fortawesome/free-solid-svg-icons';

type Props = {
    property: CalculatedPropertyDto;
    event: EventType | null;
    onUpdateProperty: (property: PropertyDto) => void;
    setEvent: React.Dispatch<React.SetStateAction<EventType | null>>;
};

const CalculatedDetails = ({ property, event, onUpdateProperty, setEvent }: Props) => {
    const { t } = useTranslate();
    const dispatch = useAppDispatch();
    const [editMode, setEditMode] = useState<boolean>(false);
    const [calculatedProperty, setCalculatedProperty] = useState<CalculatedPropertyDto>(property);
	const [unitType, setUnitType] = useState<UnitTypeDto | null>({ name: property.unittype } as UnitTypeDto);
	const [unit, setUnit] = useState<UnitDto | null>({ name: property.unit } as UnitDto);

    const updateProperty = useCallback(() => {
        try {
            setEditMode(false);
            onUpdateProperty({ 
				...calculatedProperty,
				formula: calculatedProperty.formula,
				unit: unit?.name ?? null,
			} as CalculatedPropertyDto);
        } catch (error: any) {
            dispatch(setToast({ show: true, message: error.message, severity: Severity.alert }));
            setEvent(EventType.UPDATE_PROPERTY_EVENT);
            console.log(error.message);
            return;
        }
    }, [calculatedProperty, dispatch, onUpdateProperty, setEvent]);

	const { data: units, isLoading: areUnitsLoading, isError: hasUnitsGetError } = useQuery({
		queryKey: ['unitTypes'],
		queryFn: async () => {
		  try {
			const response = await PropertiesService.getUnitTypes();
			const unitType = response.find((ut: UnitTypeDto) => ut.name === property.unittype);
			if (unitType) {
			  setUnitType(unitType);
			  setUnit(unitType.units?.find((u: UnitDto) => u.name === property.unit) ?? null);
			  return unitType.units ?? [] as UnitDto[];
			}
			return [] as UnitDto[];
		  } catch (error: unknown) {
			dispatch(setToast({ show: true, message: "Could not retrieve units", severity: Severity.alert }));
			return [] as UnitDto[];
		  }
		},
	  });
	  

    const handleFormulaChange = (newFormula: string) => {
		setCalculatedProperty((prev) => ({ ...prev, formula: newFormula }));
	};

    useEffect(() => {
        if (event === EventType.UPDATE_PROPERTY_EVENT) {
            if (property.status === Status.Draft) {
                setEditMode(true);
                return;
            }
        } else if (event === EventType.SAVE_PROPERTY_EVENT) {
            updateProperty();
        } else if (event === EventType.RESET_PROPERTY_EVENT) {
            setCalculatedProperty(property); // Reset to original property
        }
        setEditMode(false);
    }, [event, property, updateProperty]);

    return (
        <Row>
            {editMode ? (
				<>
					<Row className='pb-4 pt-4' style={{ alignItems: 'center' }}>
						<Col md={2} className='text-bold'>
							{t('unitType')}
						</Col>
						<Col md={4}>{unitType?.name ?? ''}</Col>
						
						<Col md={2} className='bold-header'>
							{t('unit')}
						</Col>
						<Col md={4}>
							{hasUnitsGetError && !areUnitsLoading && (
								<div className='unittype-error-message'>
									<FontAwesomeIcon className='pe-1 error' size='sm' icon={faWarning as IconProp} />
									<div className='error'>{t('loadingFailed')}</div>
									<FontAwesomeIcon className='ps-1 reload' icon={faRotateRight as IconProp} />
								</div>
							)}
							{areUnitsLoading && (
								<div>
									<Spinner spin className='pe-1' />
									{t('load')}
								</div>
							)}
							<Dropdown
								width='100%'
								label={t('required')}
								placeholder={t('placeholder_inputUnit')}
								onSelect={(value: UnitDto) => setUnit(value)}
								selected={unit}
								options={units?.map((u: UnitDto) => ({ label: u.name, value: u }))}
								disabled={!Boolean(unitType)}
							/>
						</Col>
					</Row>
					<hr />
					<Row className='pb-4 pt-4'>
						<Col md={2} className='text-bold'>
							{t('formula')}
						</Col>
						<Col md={10}>
						<FormulaCalculator 
							initialFormula={calculatedProperty.formula ?? ""} 
							onFormulaChange={handleFormulaChange}
							selectedUnitType={unitType?.name ?? ""}
						/>
						</Col>
					</Row>
				</>
            ) : (
				<>
					<Row className='pb-4 pt-4'>
						<Col md={2} style={{ fontWeight: 'bold' }}>
							{t('unitType')}
						</Col>
						<Col md={4}>{property?.unittype ?? ''}</Col>

						<Col md={2} style={{ fontWeight: 'bold' }}>
							{t('unit')}
						</Col>
						<Col md={4}>{property?.unit ?? ''}</Col>
					</Row>
					<hr/>
					<Row className='pb-4 pt-4'>
						<Col md={2} className='text-bold'>
							{t('formula')}
						</Col>
						<Col md={10}>
							<div>{property.formula ?? ""}</div>
						</Col>
					</Row>
				</>
            )}
        </Row>
    );
};

export default CalculatedDetails;
