import { Button, Stack } from "@mui/material";
import PropertySelector from "./components/PropertySelector";
import CountSelector from "./components/CountSelector";
import SumSelector from "./components/SumSelector";

interface Props {
    addTerm: (term: string) => void;
    formulaSelectType: string;
    setFormulaSelectType: (type: string) => void;
    selectedUnitType?: string;
}

const CalculatorExtension = ({ addTerm, formulaSelectType, setFormulaSelectType, selectedUnitType }: Props) => {

    return(
        <Stack direction='column' justifyContent='flex-start' className='border-1 flex-grow'>
            <Stack direction='row' justifyContent='space-between' className='border-bottom-1 mb-2'>
                <Button className={`formulaEditor-action-button-y border-right-1 flex-grow ${formulaSelectType === "Prop" ? "formulaEditor-action-button-selected" : ""}`} onClick={() => setFormulaSelectType("Prop")}>Prop</Button>
                <Button className={`formulaEditor-action-button-disabled border-right-1 flex-grow ${/*formulaSelectType === "Count" ? "formulaEditor-action-button-selected" : */""}`} disabled /*onClick={() => setFormulaSelectType("Count")}*/>Count</Button>
                <Button className={`formulaEditor-action-button-disabled flex-grow ${/*formulaSelectType === "Sum" ? "formulaEditor-action-button-selected" : */""}`} disabled /*onClick={() => setFormulaSelectType("Sum")}*/>Sum</Button>
            </Stack>
            <Stack direction='column' justifyContent='space-between' className='pa-1 flex-grow'>
                {formulaSelectType === "Prop" ? <PropertySelector selectedUnitType={selectedUnitType} addTerm={addTerm} /> : <></>}
                {formulaSelectType === "Count" ? <CountSelector selectedUnitType={selectedUnitType} addTerm={addTerm} /> : <></>}
                {formulaSelectType === "Sum" ? <SumSelector selectedUnitType={selectedUnitType} addTerm={addTerm} /> : <></>}
            </Stack>
        </Stack>
    );
}

export default CalculatorExtension;