import { Button, Stack } from '@mui/material';
import { useState } from 'react';
import useProperties from '../../../../../hooks/useProperties';
import { PropertyDiscriminator, SimplePropertyDto } from '../../../../../services/catalog';
import Selection from '../../../components/Selection';

type Props = {
	addTerm: (term: string) => void;
};

const PropertySelector = ({ addTerm }: Props) => {
	const [selectedProperty, setSelectedProperty] = useState<SimplePropertyDto | null>(null);
	const { properties } = useProperties();

	const onAdd = () => {
		if (selectedProperty) {
			addTerm('${' + selectedProperty.name! + '}');
			setSelectedProperty(null);
		}
	};

	return (
		<>
			<Stack direction='column'>
				<Selection
					selectables={
						properties?.filter((p) => p.propertyDiscriminator == PropertyDiscriminator.Simple) ?? []
					}
					selected={selectedProperty}
					setSelected={setSelectedProperty}
					label='Select a Property'
				/>
			</Stack>
			<Stack direction='row-reverse' gap={1}>
				<Button className='formulaEditor-action-button-y' onClick={() => onAdd()}>
					Add
				</Button>
			</Stack>
		</>
	);
};

export default PropertySelector;
