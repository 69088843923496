import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ClassificationDto, PropertyDto } from '../../../services/catalog';

type SelectionProps = {
	label: string;
	nullable?: boolean;
};

type ClassificationSelectionProps = {
	selectables: ClassificationDto[];
	selected: ClassificationDto | null;
	setSelected: (selected: ClassificationDto | null) => void;
};

type PropertySelectionProps = {
	selectables: PropertyDto[];
	selected: PropertyDto | null;
	setSelected: (selected: PropertyDto | null) => void;
};

const Selection = ({
	label,
	nullable,
	selectables,
	selected,
	setSelected,
}: SelectionProps & (ClassificationSelectionProps | PropertySelectionProps)) => {
	return (
		<FormControl className='mb-4'>
			<InputLabel id='demo-simple-select-label'>{label}</InputLabel>
			<Select
				labelId='demo-simple-select-label'
				id='demo-simple-select'
				label={label}
				variant='standard'
				value={selected?.id ?? '*'}
				onChange={(e) => setSelected(selectables?.find((c) => c.id === e.target.value) ?? null)}
			>
				{nullable && <MenuItem value='*'>*</MenuItem>}
				{selectables?.map((item) => (
					<MenuItem key={item.id} value={item.id ?? ''}>
						{item.name}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default Selection;
