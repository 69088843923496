import { useQuery } from '@tanstack/react-query';
import { ClassificationsService } from '../services/catalog';

const useClassifications = () => {
	const { data: classifications } = useQuery({
		queryKey: ['classifications'],
		queryFn: () => ClassificationsService.getAllClassifications(),
	});

	return { classifications };
};

export default useClassifications;
