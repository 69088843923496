//Style
import './Container.scss';

import PropertyHeader from '../forms/Properties/PropertyHeader';
import PropertyTable from '../forms/Properties/PropertyTable';

const PropertiesContainer = () => {
	return (
		<>
			<PropertyHeader />
			<PropertyTable />
		</>
	);
};

export default PropertiesContainer;
