// React Libraries
import { useEffect } from 'react';

// Models & Constants

// STYLE
import '../../styles/PropertyModal.scss';
import { EventType } from '../../../../models/models';
import { SimplePropertyDto, PropertyDto, Status } from '../../../../services/catalog';

interface Props {
	property: SimplePropertyDto;
	event: EventType | null;
	onUpdateProperty: (property: PropertyDto) => void;
}

const BasicDetails = ({ property, event, onUpdateProperty }: Props) => {

	useEffect(() => {
		if (event === EventType.UPDATE_PROPERTY_EVENT) {
			if (property.status === Status.Draft)
			{
            	return;
			}
		} else if (event === EventType.SAVE_PROPERTY_EVENT) {
			updateProperty();
		}
	}, [event]);

	const updateProperty = () => {
		try {
			const updatedProperty = { ...property };
			onUpdateProperty(updatedProperty);
		} catch (error: any) {
			console.log(error.message);
		}
	};

	return (
		<>
		</>
	);
};

export default BasicDetails;

