import { Button, Stack } from '@mui/material';
import { useState } from 'react';
import useClassifications from '../../../../../hooks/useClassifications';
import useProperties from '../../../../../hooks/useProperties';
import { ClassificationDto, SimplePropertyDto } from '../../../../../services/catalog';
import Selection from '../../../components/Selection';

type Props = {
	addTerm: (term: string) => void;
};

const CountSelector = ({ addTerm }: Props) => {
	const [selectedClassification, setSelectedClassification] = useState<ClassificationDto | null>(null);
	const [selectedProperty, setSelectedProperty] = useState<SimplePropertyDto | null>(null);

	const { classifications } = useClassifications();
	const { properties } = useProperties();

	const OnAdd = () => {
		if (!selectedProperty && !selectedClassification) return;
		addTerm('$count{' + (selectedClassification?.name ?? '*') + '.' + (selectedProperty?.name ?? '*') + '}');
		setSelectedClassification(null);
		setSelectedProperty(null);
	};

	return (
		<>
			<Stack direction='column'>
				<Selection
					selectables={classifications ?? []}
					selected={selectedClassification}
					setSelected={setSelectedClassification}
					label='Select a Classification'
					nullable
				/>
				<Selection
					selectables={properties ?? []}
					selected={selectedProperty}
					setSelected={setSelectedProperty}
					label='Select a Property'
					nullable
				/>
			</Stack>
			<Stack direction='row-reverse' gap={1}>
				<Button className='formulaEditor-action-button-y' onClick={() => OnAdd()}>
					Add
				</Button>
			</Stack>
		</>
	);
};

export default CountSelector;
