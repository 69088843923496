// STYLE
import './PropertyModal.scss';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faMinus, faPlus, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextInput } from '@lbc-toolkit/textinput';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAppSelector } from '../../context/hooks';
import { RootState } from '../../context/store';
import { EventType } from '../../models/models';
import { ObjectPropertyDto, PropertyAssignmentDto, PropertyDiscriminator, PropertyDto, SimplePropertyDto, TablePropertyDto } from '../../services/catalog';


interface PropertyAssignmentsProps {
	property: PropertyDto | undefined;
	title: string;
	event: EventType | null;
	propertyType: PropertyDiscriminator;
	assignedProperties: PropertyAssignmentDto[];
	setAssignedProperties: React.Dispatch<React.SetStateAction<PropertyAssignmentDto[]>>;
	onCreateProperty: (property: PropertyDto) => void;
	resetEvent: () => void;
}

/**
 * This component is used to assign properties to a table or object property.
 */
const PropertyAssignments = ({
	property,
	title,
	event,
	propertyType,
	assignedProperties,
	setAssignedProperties,
	onCreateProperty,
	resetEvent,
}: PropertyAssignmentsProps) => {
	const [searchValue, setSearchValue] = useState<string>('');
	const properties = useAppSelector((state: RootState) => state.properties.properties);
	const [inputValue, setInputValue] = useState<string>('');
	const [selectedProperty, setSelectedProperty] = useState<PropertyDto | null>(null);

	useEffect(() => {
		const buildTableProperty = () => {
			try {
				if (propertyType === PropertyDiscriminator.Table) {
					const property: TablePropertyDto = {};
					property.columnProperties = assignedProperties;
					onCreateProperty(property);
				} else if (propertyType === PropertyDiscriminator.Object) {
					const property: ObjectPropertyDto = {};
					property.properties = assignedProperties;
					onCreateProperty(property);
				}
			} catch (error: any) {
				console.error(error.message);
				resetEvent();
			}
		};
		if (event === EventType.CREATE_PROPERTY_EVENT) {
			buildTableProperty();
		}
	}, [event, onCreateProperty, resetEvent, assignedProperties]);

	/**
	 * Filters properties based on the provided search value.
	 */
	const filteredProperties = properties.filter((property) =>
		property.name!.toLowerCase().includes(searchValue.toLowerCase()),
	);

	/**
	 * Handles the action when the check icon is clicked.
	 * If a property is selected and an input value is present,
	 * it creates a new column property and updates the state.
	 */
	const handlePropertyAssignment = () => {
		if (selectedProperty) {
			const newColumnProperty: PropertyAssignmentDto = {
				name: inputValue === '' ? selectedProperty.name! : inputValue,
				property: selectedProperty,
			};
			setAssignedProperties([...assignedProperties, newColumnProperty]);
			setSelectedProperty(null);
			setInputValue('');
		}
	};

	const cancelPropertyAssignment = () => {
		setSelectedProperty(null);
		setInputValue('');
	};

	const removeColumnProperty = (columnToRemove: PropertyAssignmentDto) => {
		setAssignedProperties((prevColumns) => {
			const newColumns = prevColumns.filter((column) => column.name !== columnToRemove.name);
			return newColumns;
		});
	};

	const getTooltip = (property: SimplePropertyDto): string => {
		let tooltipLines: string[] = [];
		if (property.unittype) {
			tooltipLines.push(`Unit Type: ${property.unittype}`);
		}
		if (property.unit) {
			tooltipLines.push(`Unit: ${property.unit}`);
		}
		return tooltipLines.join('\n');
	};

	return (
		<Row className='pb-4 pt-4'>
			<Col md={6}>
				<Row className='mb-3'>
					<Col md={4} className='bold-header'>
						Available properties
					</Col>
					<Col md={8}>
						<TextInput
							width='100%'
							placeholder='Search...'
							value={searchValue}
							onChange={(value) => setSearchValue(value)}
						/>
					</Col>
				</Row>

				<Paper className='properties-table-paper'>
					<TableContainer className='property-table-container'>
						<Table stickyHeader size='small'>
							<TableHead>
								<TableRow>
									<TableCell className='bold-header'>Name</TableCell>
									<TableCell className='bold-header'>Datatype</TableCell>
									<TableCell className='bold-header'>Add</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{filteredProperties.map((filteredProperty) => {
									if (property && filteredProperty.id === property.id) {
										return;
									}
									return (
										<TableRow className='table-row' key={filteredProperty.id}>
											<Tooltip title={getTooltip(filteredProperty)}>
												<TableCell align='left' className='text-truncate'>
													{filteredProperty.name!}
												</TableCell>
											</Tooltip>
											<Tooltip title={getTooltip(filteredProperty)}>
												<TableCell align='left' className='text-truncate'>
													{filteredProperty.propertyDiscriminator}
												</TableCell>
											</Tooltip>
											<TableCell align='left' className='text-truncate'>
												<button
													className='action-button'
													onClick={() => {
														setSelectedProperty(filteredProperty);
													}}
												>
													<FontAwesomeIcon icon={faPlus as IconProp} />
												</button>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
				{selectedProperty && (
					<div className='input-with-icons mt-1'>
						<TextInput
							width='100%'
							placeholder={selectedProperty.name!}
							value={inputValue}
							onChange={(value: string) => {
								setInputValue(value);
							}}
						/>
						<FontAwesomeIcon icon={faCheck as IconProp} onClick={handlePropertyAssignment} />
						<FontAwesomeIcon icon={faTimes as IconProp} onClick={cancelPropertyAssignment} />
					</div>
				)}
			</Col>
			<Col md={6}>
				<Row className='bold-header mb-3'>{title}</Row>
				<Paper className='property-table-paper'>
					<TableContainer className='property-table-container'>
						<Table stickyHeader size='small'>
							<TableHead>
								<TableRow>
									<TableCell className='bold-header'>Name</TableCell>
									<TableCell className='bold-header'>Property</TableCell>
									<TableCell className='bold-header'>Remove</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{assignedProperties.map((property: PropertyAssignmentDto) => (
									<TableRow className='table-row' key={property.id}>
										<TableCell align='left' className='text-truncate'>
											{property.name}
										</TableCell>
										<TableCell align='left' className='text-truncate'>
											{property.property?.name}
										</TableCell>
										<TableCell align='left' className='text-truncate'>
											<button
												onClick={() => {
													removeColumnProperty(property);
												}}
												className='action-button'
											>
												<FontAwesomeIcon icon={faMinus as IconProp} />
											</button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Col>
		</Row>
	);
};

export default PropertyAssignments;
