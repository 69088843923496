// COMPONENTS
import { Modal } from 'react-bootstrap';
import DetailsNavbar from '../../components/DetailsModalNavbar/DetailsNavbar';
import General from './components/General/General';
import Localization from './components/Localization/Localization';
import { useAppSelector } from '../../context/hooks';
import { RootState } from '../../context/store';

const PropertyDetailsContent = () => {
	const activeDetailsTab = useAppSelector((state: RootState) => state.app.activeDetailsTab);
	const currentProperty = useAppSelector((state: RootState) => state.properties.currentProperty);

	/**
	 * Renders the content, based on a given string.
	 * @param detailsTab Given string to differ between content.
	 */
	function renderContent(detailsTab: string) {
		if (currentProperty === null) {
			return <div />;
		}
		switch (detailsTab) {
			case 'general':
				return <General property={currentProperty} />;
			case 'localization':
				return <Localization />;
		}
	}

	return (
		<Modal.Body className='details-modal-content-layout'>
			<DetailsNavbar />
			<div className='details-content'>{renderContent(activeDetailsTab)}</div>
		</Modal.Body>
	);
};

export default PropertyDetailsContent;
