import { Col, Row } from 'react-bootstrap';
// TRANSLATION
import { useTranslate } from '@tolgee/react';
import { DataType, PropertyDto, SimplePropertyDto } from '../../../../services/catalog';
import { EventType } from '../../../../models/models';
import UnitDetails from './UnitDetails';
import BasicDetails from './BasicDetails';
import '../../styles/PropertyModal.scss';

type Props = {
	property: SimplePropertyDto;
	event: EventType | null;
	onUpdateProperty: (property: PropertyDto) => void;
	setEvent: React.Dispatch<React.SetStateAction<EventType | null>>;
	onValidationChange: (isValid: boolean) => void;
};

const SimpleDetails = ({ property, event, onUpdateProperty, setEvent, onValidationChange }: Props) => {
	const { t } = useTranslate();

	const getExtendenFormByDataType = () => {
		switch ((property as SimplePropertyDto).dataType) {
			case DataType.Integer:
			case DataType.Double: {
				return (
					<UnitDetails
						property={property}
						event={event}
						onUpdateProperty={onUpdateProperty}
						onValidationChange={onValidationChange}
						setEvent={setEvent}
					/>
				);
			}
			case DataType.Boolean:
			case DataType.String: {
				return <BasicDetails property={property} event={event} onUpdateProperty={onUpdateProperty} />;
			}
		}
		return <></>;
	};

	return (
		<>
			<Row className='pb-4 pt-4'>
				<Col md={2} className='text-bold'>
					{t('dataType')}
				</Col>
				<Col md={10}>{property.dataType}</Col>
			</Row>
			<hr />
			{getExtendenFormByDataType()}
		</>
	);
};

export default SimpleDetails;
