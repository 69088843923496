//STYLING
import '../../../../styling/Details.scss';
import '../../../../styling/Modal.scss';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faQuestionCircle, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@lbc-toolkit/button';
import { Spinner } from '@lbc-toolkit/spinner';
import { TextArea } from '@lbc-toolkit/textarea';
import { TextInput } from '@lbc-toolkit/textinput';
import {
	Checkbox,
	FormControl,
	IconButton,
	MenuItem,
	Paper,
	Select,
	SelectChangeEvent,
	Tooltip
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Button as BootstrapButton, Col, Form, InputGroup, Row } from 'react-bootstrap';

// REDUX

// TRANSLATION
import { useTranslate } from '@tolgee/react';
import ClassificationTree from '../../../../components/navigation/treeviews/ClassificationTree';
import { setToast } from '../../../../context/appReducer';
import { setClassTreeNeedsReload, setCurrentClass, setEditMode, setSelectedParentClass } from '../../../../context/classesReducer';
import { useAppDispatch, useAppSelector } from '../../../../context/hooks';
import { RootState } from '../../../../context/store';
import useSearchedClassifications from '../../../../hooks/UseSearchedClassifications';
import { Severity } from '../../../../models/models';
import { ClassificationDto, ClassificationsService, Status, UsageType } from '../../../../services/catalog';

const General = () => {
	const { t } = useTranslate();
	const dispatch = useAppDispatch();
	const currentClass = useAppSelector((state: RootState) => state.classes.currentClass);
	const editMode = useAppSelector((state: RootState) => state.classes.editMode);
	const classTreeNeedsReload = useAppSelector((state: RootState) => state.classes.classTreeNeedsReload);
	const [classTreeSearchText, setClassTreeSearchText] = useState<string>('');
	const { classifications } = useSearchedClassifications(classTreeSearchText);
	const [wantsToClose, setWantsToClose] = useState<boolean>(false);
	const [isDraft, setIsDraft] = useState<boolean>(false);
	const [isPublished, setIsPublished] = useState<boolean>(false);

	// Input states
	const [name, setName] = useState<string>(currentClass?.name!);
	const [description, setDescription] = useState<string>(currentClass?.description!);
	const [usageType, setUsageType] = useState<UsageType>(currentClass?.usageType!);
	const [isCompositionVariant, setIsCompositionVariant] = useState<boolean>(
		currentClass?.usageType === UsageType.CompositionVariant,
	);
	const [status, _setStatus] = useState<Status>(currentClass?.status!);
	const [tempStatus, setTempStatus] = useState<Status>(currentClass?.status!);
	const selectedParentClass = useAppSelector((state: RootState) => state.classes.selectedParentClass);
	const [originalParent, setOriginalParent] = useState<ClassificationDto | null>(selectedParentClass);

	useEffect(() => {
		if (currentClass?.parentId !== null) {
			ClassificationsService.getClassification(currentClass?.parentId!).then((parentClass: ClassificationDto) => {
				dispatch(setSelectedParentClass(parentClass));
				setOriginalParent(parentClass);
			});
		}
	}, []);

	useEffect(() => {
		if (selectedParentClass?.status == Status.Draft) {
			setTempStatus(Status.Draft);
		}
		if (selectedParentClass?.usageType) {
			onChangeUsageType(selectedParentClass?.usageType);
		}
	}, [selectedParentClass]);

	useEffect(() => {
		setIsDraft(currentClass?.status === Status.Draft);
		setIsPublished(currentClass?.status === Status.Published);
	}, [currentClass]);

	/**
	 * Handles the update of the class
	 */
	const OnSaveEdit = () => {
		const updatedClass: ClassificationDto = {
			id: currentClass?.id,
			name: name,
			description: description,
			usageType: usageType,
			parentId: selectedParentClass?.id,
			children: currentClass?.children,
			status: tempStatus,
		};

		ClassificationsService.updateClassification(updatedClass)
			.then(() => {
				dispatch(setCurrentClass(updatedClass));
				setUsageType(updatedClass.usageType!);
				dispatch(
					setToast({
						show: true,
						severity: Severity.success,
						message: t('updateClassSuccess'),
					}),
				);

				dispatch(setEditMode(false));
			})
			.catch((_error: any) => {
				dispatch(
					setToast({
						show: true,
						severity: Severity.warning,
						message: t('updateFailed'),
					}),
				);
			})
			.finally(() => {
				dispatch(setClassTreeNeedsReload(true));
			});
	};

	/**
	 * Renders the usage type in non edit mode
	 * @returns
	 */
	const renderUsageTypeInNonEditMode = () => {
		var ut = usageType;
		if (!isNaN(Number(usageType))) {
			ut = UsageType[usageType];
		}
		if (ut === UsageType.CompositionVariant) {
			ut = UsageType.Composition;
		}
		return ut;
	};

	/**
	 * Handles the change of the composition variant checkbox
	 * @param value
	 */
	const onChangeCompositionVariantSelection = (value: boolean) => {
		if (value) {
			setUsageType(UsageType.CompositionVariant);
			setIsCompositionVariant(true);
		} else {
			setUsageType(UsageType.Composition);
			setIsCompositionVariant(false);
		}
	};

	/**
	 * Handles the change of the usage type
	 * @param value
	 */
	const onChangeUsageType = (value: UsageType) => {
		if (selectedParentClass?.usageType == UsageType.Collector) {
			setUsageType(currentClass?.usageType!);
		} else {
			setUsageType(value);
		}
		if (usageType === UsageType.CompositionVariant) {
			setIsCompositionVariant(true);
		}
	};

	/**
	 * Renders the composition variant checkbox depending on the usageType and editMode + draft status
	 * @returns
	 */
	const renderCompositionVariantCheckBox = () => {
		if (usageType === UsageType.Composition || usageType === UsageType.CompositionVariant) {
			return (
				<>
					<Row md={8} className='align-items-center ml-1'>
						<Col className='pr-0'>
							<Checkbox
								checked={isCompositionVariant || usageType === UsageType.CompositionVariant}
								disabled={true}
								onChange={(value) => onChangeCompositionVariantSelection(value.target.checked)}
							/>
						</Col>
						<Col className='pl-0'>{t('variant')}</Col>
					</Row>
					<hr />
				</>
			);
		}
	};

	/**
	 * Handles the edit button and activates editmode
	 */
	const OnEdit = () => {
		dispatch(setClassTreeNeedsReload(true));
		dispatch(setEditMode(true));
	};

	/**
	 * Resets all input fields to their original state
	 */
	const ResetInputs = () => {
		setName(currentClass?.name!);
		setDescription(currentClass?.description!);
		setUsageType(currentClass?.usageType!);
		setTempStatus(currentClass?.status!);
		dispatch(setSelectedParentClass(originalParent));
	};

	/**
	 * Handles deactivating of the edit modal
	 */
	const OnCancelEdit = () => {
		if (
			name !== currentClass?.name ||
			description !== currentClass?.description ||
			usageType !== currentClass?.usageType ||
			selectedParentClass !== currentClass?.parentId ||
			tempStatus !== currentClass?.status
		) {
			setWantsToClose(true);
		} else dispatch(setEditMode(false));
	};

	/**
	 * Handles abort of deactivating edit mode
	 */
	const OnAbortClose = () => {
		setWantsToClose(false);
	};

	/**
	 * Handles confirmation of deactivating edit mode
	 */
	const OnConfirmClose = () => {
		dispatch(setEditMode(false));
		setWantsToClose(false);
		ResetInputs();
	};

	/**
	 * Handling validation of the parent status to avoid publishing a child of a draf parent
	 */

	const handleStatusChange = (event: SelectChangeEvent) => {
		setTempStatus(event.target.value as Status);
	};

	function isItemDisabled(value: Status): boolean | undefined {
		if (status == Status.Draft) {
			return value == Status.Deprecated || (selectedParentClass?.status == Status.Draft && value != Status.Draft);
		}
		if (status == Status.Published) {
			return value == Status.Draft;
		}
		if (tempStatus == Status.Published) {
			return value == Status.Draft;
		}
		if (tempStatus == Status.Deprecated) {
			return value == Status.Draft;
		}
		if (status == Status.Deprecated) {
			return value != Status.Deprecated;
		}
	}
	/**
	 * Return an icon for the dropdown list
	 */
	const newIcon = (props) => {
		return <FontAwesomeIcon {...props} icon={faChevronDown} style={{ transform: 'rotate(0deg)' }} />;
	};

	const statusDropdown = () => {
		{
			if (status != Status.Deprecated) {
				return (
					<FormControl style={{ minWidth: 280 }} sx={{ borderRadius: 0 }}>
						<Select
							IconComponent={newIcon}
							value={tempStatus}
							onChange={handleStatusChange}
							sx={{ height: 40 }}
							defaultValue={Status.Draft}
						>
							{Object.keys(Status).map((key) => (
								<MenuItem key={key} value={Status[key]} disabled={isItemDisabled(Status[key])}>
									{Status[key]}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				);
			} else {
				<FormControl style={{ minWidth: 280 }} sx={{ borderRadius: 0 }}>
					<Select IconComponent={newIcon} value={status} sx={{ height: 40 }} defaultValue={status}></Select>
				</FormControl>;
			}
		}
	};
	/**
	 * Sets temporary usage type
	 */
	const handleUsageTypeChange = (event: SelectChangeEvent) => {
		if (usageType === UsageType.CompositionVariant) {
			setIsCompositionVariant(true);
		}
		setUsageType(event.target.value as UsageType);
	};
	/**
	 * Function which removes a usage type option based on condition in the classification
	 */
	function isUsageTypeDisabledClassification(value: UsageType): boolean {
		if (value == UsageType.Collector) {
			return true;
		}
		return value == currentClass?.usageType;
	}
	/**
	 * Function which removes a usage type option based on condition
	 */
	function isUsageTypeDisabled(value: UsageType): boolean {
		if (currentClass?.usageType === UsageType.CompositionVariant && value == UsageType.Composition) {
			return true;
		}
		return value === currentClass?.usageType;
	}
	/**
	 * Dropdown list for usage types with the logic allowing
	 * restriction of a usage type option
	 */

	const usageTypeDropdown = () => {
		return (
			<FormControl>
				<Select
					IconComponent={newIcon}
					value={usageType === 'CompositionVariant' ? 'Composition' : usageType || ''}
					onChange={handleUsageTypeChange}
					sx={{ height: 40 }}
				>
					{Object.keys(UsageType).map((key) => {
						const value = UsageType[key];
						if (value !== UsageType.CompositionVariant) {
							return (
								<MenuItem key={key} value={value} disabled={!isUsageTypeDisabled(value)}>
									{value === 'CompositionVariant' ? 'Composition' : value}
								</MenuItem>
							);
						}
						return null;
					})}
				</Select>
			</FormControl>
		);
	};
	/**
	 *
	 * Returns the list of classes without the current class itself
	 */
	const filteredClassifications = (): ClassificationDto[] => {
		const filteredClassifications = classifications.filter(
			(classification) =>
				classification &&
				classification.usageType !== undefined &&
				isUsageTypeDisabledClassification(classification.usageType) &&
				classification.id !== currentClass?.id,
		);
		return filteredClassifications;
	};

	return (
		<Paper className='details-general-layout'>
			<div className='details-general-content'>
				<Row className='details-general-edit-row'>
					{!editMode && (isDraft || isPublished) ? (
						<IconButton className='details-general-edit-button' onClick={OnEdit}>
							<FontAwesomeIcon icon={faEdit as IconProp} />
						</IconButton>
					) : (
						<div />
					)}
				</Row>
				<Row className='pb-4 pt-4'>
					<Col md={3}>{t('name')}</Col>
					<Col md={9}>
						{editMode && isDraft ? (
							<TextInput
								width='100%'
								label={t('required')}
								value={name}
								placeholder={t('placeholderInputName')}
								onChange={(value) => setName(value)}
							/>
						) : (
							<>{currentClass!.name}</>
						)}
					</Col>
				</Row>
				<hr />
				<Row className='pb-4 pt-4'>
					<Col md={3}>{t('description')}</Col>
					<Col md={9}>
						{editMode && isDraft ? (
							<>
								<TextArea
									width='100%'
									label={t('required')}
									value={description}
									placeholder={t('enterDescription')}
									onChange={(value: string) => {
										setDescription(value);
									}}
								/>
								<div
									className={
										description.length > 255
											? 'modal-content-description-length-tooLong'
											: 'modal-content-description-length'
									}
								>
									{description.length > 255
										? t('tooManyCharacters') + description.length + '/255'
										: description.length + '/255'}
								</div>
							</>
						) : (
							<p>{currentClass!.description}</p>
						)}
					</Col>
				</Row>
				<hr />
				<Row className='pb-4 pt-4'>
					<Col md={3} className='align-items-center'>
						{t('usage')}
						{/* <FontAwesomeIcon  className="modal-content-type-indicator" color="black" icon={usageIcon} /> */}
					</Col>
					<Col md={9} className='modal-content-tooltip-row'>
						{editMode && isDraft ? (
							<>
								{usageTypeDropdown()}
								<Tooltip
									className='modal-content-tooltip'
									placement='right'
									title={t('info_usageType')}
									arrow
								>
									<div>
										<FontAwesomeIcon size='sm' color='gray' icon={faQuestionCircle as IconProp} />
									</div>
								</Tooltip>
								{renderCompositionVariantCheckBox()}
							</>
						) : (
							<div className='align-items-center'>
								<>{renderUsageTypeInNonEditMode()}</>
								<>{renderCompositionVariantCheckBox()}</>
							</div>
						)}
					</Col>
				</Row>
				<hr />
				<Row className='pb-4 pt-4'>
					<Col md={3}>{t('status')}</Col>
					<Col md={9}>
						{editMode && (isDraft || isPublished) ? (
							<>{statusDropdown()}</>
						) : (
							<div>{!isNaN(Number(status)) ? Status[status] : status}</div>
						)}
					</Col>
				</Row>
				<hr />
				<Row className='pb-4 pt-4'>
					<Col md={3}>{t('parentClass')}</Col>
					<Col md={9}>
						{editMode && isDraft ? (
							<>
								<div className='modal-content-treeview'>
									<div className='modal-content-treeview-header'>
										<h6 className='modal-content-treeview-header-title'>{t('classifications')}</h6>
										<InputGroup className='modal-content-treeview-header-inputgroup'>
											<Tooltip
												title={
													classTreeSearchText.trim().length < 3 ? t('atLeast3Characters') : ''
												}
												placement='bottom-start'
											>
												<Form.Control
													className='modal-content-treeview-header-searchBar'
													placeholder={t('search')}
													onChange={(e) => setClassTreeSearchText(e.target.value)}
												/>
											</Tooltip>
											<BootstrapButton className='modal-searchbar-button'>
												{classTreeNeedsReload ? (
													<Spinner style={{ color: 'black' }} spin={true} />
												) : (
													<FontAwesomeIcon
														className='fa-xs'
														id='modalSearchIconBtn'
														color='black'
														icon={faSearch as IconProp}
													/>
												)}
											</BootstrapButton>
										</InputGroup>
									</div>
									<div className='modal-content-treeview-container'>
										<ClassificationTree classifications={filteredClassifications()} />
									</div>
								</div>
							</>
						) : (
							<div>{selectedParentClass?.name ?? ''}</div>
						)}
					</Col>
				</Row>
			</div>
			<div className='details-general-edit-bottom-row'>
				{editMode ? (
					<>
						{wantsToClose ? (
							<>
								<div className='p-2'>{t('info_unsavedDataGetsLost')}</div>
								<Button className='p-2' type='secondary' onClick={OnAbortClose} label={t('no')} />
								<Button className='p-2' type='primary' onClick={OnConfirmClose} label={t('yes')} />
							</>
						) : (
							<>
								<Button className='p-2' type='secondary' onClick={OnCancelEdit} label={t('cancel')} />
								<Button className='p-2' type='primary' onClick={OnSaveEdit} label={t('save')} />
							</>
						)}
					</>
				) : (
					<></>
				)}
			</div>
		</Paper>
	);
};

export default General;
