// STYLING
import '../../styling/Details.scss';

import { Modal } from 'react-bootstrap';
import DetailsNavbar from '../../components/DetailsModalNavbar/DetailsNavbar';
import { useAppSelector } from '../../context/hooks';
import { RootState } from '../../context/store';
import General from './components/General/General';
import Localization from './components/Localization/Localization';
import Mapping from './components/Mapping/Mapping';

const ClassDetailsContent = () => {
	const activeDetailsTab = useAppSelector((state: RootState) => state.app.activeDetailsTab);
	const currentClass = useAppSelector((state: RootState) => state.classes.currentClass);

	/**
	 * Renders the content, based on a given string.
	 * @param activeDetailsTab Given string to differ between content.
	 */
	function renderContent(activeDetailsTab: string) {
		if (currentClass === null) {
			return <div />;
		}
		switch (activeDetailsTab) {
			case 'general':
				return <General />;
			case 'properties':
				return <Mapping />;
			case 'localization':
				return <Localization />;
		}
	}

	return (
		<Modal.Body className='details-modal-content-layout'>
			<DetailsNavbar />
			<div className='details-content'>{renderContent(activeDetailsTab)}</div>
		</Modal.Body>
	);
};

export default ClassDetailsContent;
