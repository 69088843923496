// STYLING
import { useTranslate } from '@tolgee/react';
import { Modal } from 'react-bootstrap';
import { setActiveDetailsTab, setDetailsMode, setToast } from '../../context/appReducer';
import { setEditMode as setClassEditMode, setCurrentClass, setSelectedParentClass } from '../../context/classesReducer';
import { useAppDispatch, useAppSelector } from '../../context/hooks';
import {
	setAddEnumMode,
	setCurrentProperty,
	setEditMode as setPropertyEditMode,
} from '../../context/propertiesReducer';
import { RootState } from '../../context/store';
import { Severity } from '../../models/models';
import ClassDetailsContent from '../DetailsClassificationModal/ClassDetailsContent';
import PropertyDetailsContent from '../DetailsPropertyModal/PropertyDetailsContent';
import './styles/Modal.scss';

export const DetailsModal = () => {
	const { t } = useTranslate();
	const dispatch = useAppDispatch();
	const activeTab = useAppSelector((state: RootState) => state.app.activeTab);
	const detailsMode = useAppSelector((state: RootState) => state.app.detailsMode);
	const classesEditMode = useAppSelector((state: RootState) => state.classes.editMode);
	const propertiesEditMode = useAppSelector((state: RootState) => state.properties.editMode);
	const addMappingMode = useAppSelector((state: RootState) => state.mappings.addMappingMode);
	const content: JSX.Element = activeTab === 'classes' ? <ClassDetailsContent /> : <PropertyDetailsContent />;

	/**
	 * Handles closing of the modal
	 */
	const OnClose = () => {
		if (!classesEditMode && !propertiesEditMode && !addMappingMode) {
			ResetStates();
		} else {
			dispatch(
				setToast({
					show: true,
					severity: Severity.warning,
					message: t('finishEditingFirst'),
				}),
			);
		}
	};

	/**
	 * Resets all states when closing the modal
	 */
	const ResetStates = () => {
		dispatch(setDetailsMode(false));
		dispatch(setCurrentClass(null));
		dispatch(setCurrentProperty(null));
		dispatch(setAddEnumMode(false));
		dispatch(setPropertyEditMode(false));
		dispatch(setClassEditMode(false));
		dispatch(setActiveDetailsTab('general'));
		dispatch(setSelectedParentClass(null));
	};

	return (
		<Modal dialogClassName='details-modal-layout' centered show={detailsMode} onHide={OnClose}>
			<Modal.Header closeButton>
				<Modal.Title>{t('details')}</Modal.Title>
			</Modal.Header>
			{content}
		</Modal>
	);
};
