import { useQuery } from '@tanstack/react-query';
import { PropertiesService } from '../services/catalog';

const useProperties = () => {
	const { data: properties } = useQuery({
		queryKey: ['properties'],
		queryFn: () => PropertiesService.getProperties(),
	});

	return { properties };
};

export default useProperties;
