// STYLING
import './Mappings.scss';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import PropertyIcon from '../../../../components/icons/PropertyIcon';
import { setToast } from '../../../../context/appReducer';
import { useAppDispatch, useAppSelector } from '../../../../context/hooks';
import { setMappingsListNeedsReload } from '../../../../context/mappingsReducer';
import { RootState } from '../../../../context/store';
import { Severity } from '../../../../models/models';
import { MappingDto, MappingsService } from '../../../../services/catalog';

interface Props {
	mapping: MappingDto;
	deletable?: boolean;
}

const MappingTableProperty = ({ mapping, deletable = true }: Props) => {
	const dispatch = useAppDispatch();
	const currentClass = useAppSelector((state: RootState) => state.classes.currentClass);
	const [isHovering, setIsHovering] = useState(false);

	/**
	 * Handles the deletion of mappings
	 */
	const OnDelete = () => {
		MappingsService.removeMapping(currentClass?.id!, mapping.property!.id!)
			.then(() => {
				dispatch(
					setToast({
						show: true,
						severity: Severity.success,
						message: t('deleteMappingSuccess'),
					}),
				);
				dispatch(setMappingsListNeedsReload(true));
			})
			.catch(() => {
				dispatch(
					setToast({
						show: true,
						severity: Severity.alert,
						message: t('errorFetchingData'),
					}),
				);
			});
	};

	return (
		<tr onMouseOver={() => setIsHovering(true)} onMouseOut={() => setIsHovering(false)}>
			<td className='mapping-property'>
				<div>{mapping.property && <PropertyIcon property={mapping.property} showPropertyName={true} />}</div>

				<div className='mapping-property-type'>
					{mapping.propertyUsageType && <span> {mapping.propertyUsageType}</span>}
				</div>
				{deletable ? (
					<IconButton hidden={!isHovering} className='details-general-edit-button' onClick={OnDelete}>
						<FontAwesomeIcon color='#eb4034' className='fa-2xs' icon={faX as IconProp} />
					</IconButton>
				) : (
					<div />
				)}
			</td>
		</tr>
	);
};

export default MappingTableProperty;
